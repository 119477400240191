<template>
  <!-- TODO: Translation -->
  <!-- TODO: Select Image to Uplod styling -->
  <form @submit.prevent="uploadImage">
    <BaseModal v-model="model" @close-modal="closeModal">
      <template v-slot:header> {{ $t('settings.imageUpload.header') }} </template>
      <div class="px-6 py-6 min-h-96">
        <div class="flex items-center justify-center w-full h-full">
          <div v-if="!image">
            <BaseButton @click="file.click()" class="button button-lime">
              <input type="file" ref="file" @change="previewImage" accept="image/*" hidden />
              Bild vom Computer auswählen
            </BaseButton>
          </div>
          <div v-else class="flex flex-row gap-8">
            <div class="flex flex-col justify-center gap-2 py-2">
              <BaseButton class="button" @click="doZoom(0.1)">
                <font-awesome-icon :icon="['fa-kit', 'tl-zoom-in']" class="text-lg" />
              </BaseButton>
              <input type="range" name="zoom" v-model="zoom" min="0.0" max="0.95" step="0.001" class="h-96 input-zoom"
                @input="onZoom" @change="onZoom" @mousedown="onZoomStart" @mouseup="onZoomEnd" />
              <BaseButton class="button" @click="doZoom(-0.1)"><font-awesome-icon :icon="['fa-kit', 'tl-zoom-out']"
                  class="text-lg" /></BaseButton>
            </div>
            <div class="">
              <Cropper class="min-w-[460px] max-w-[460px] aspect-square" :src="image.src" ref="croppy"
                :background-class="'bg-white chessboard'" :foreground-class="'bg-mossgray '"
                :stencil-component="ImageUploadStencil" :stencil-props="{
                  movable: false
                }" :debounce="0" :canvas="{
                  width: 312,
                  height: 312,
                  maxWidth: 312,
                  maxHeight: 312,
                  fillColor: '#fff'
                }" :stencil-size="{
                  width: 312,
                  height: 312
                }" image-restriction="stencil" :resize-image="{
                  adjustStencil: false
                }" @change="onChange" :default-size="{
                  width: 312,
                  height: 312
                }" :transitions="false" :auto-zoom="false" />
            </div>

            <div class="">
              <span>Vorschau:</span>
              <div class="drop-shadow-[0_0_3px_#333333] mt-3">
                <div class="bg-white squircle-clip" style="max-width: 120px">
                  <preview :image="result.image" :coordinates="result.coordinates" :width="120" :height="120" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="py-2 text-sm text-red-500" v-if="errors.image">
          <template v-for="message in errors.image" :key="message">
            <div>
              {{ message }}
            </div>
          </template>
        </div>
      </div>
      <template v-slot:footer>
        <div class="flex items-center justify-end w-full space-x-4">
          <BaseButton class="button-gray" @click="model = false" type="button">
            {{ $t('cancel') }}
          </BaseButton>

          <BaseButton class="button-mossgray" type="submit" @click="uploadImage">
            {{ $t('upload') }}
          </BaseButton>
        </div>
      </template>
      <LoadingSpinner v-model="isLoading" :overAll="true"></LoadingSpinner>
    </BaseModal>
  </form>

  <SquirclePath></SquirclePath>
</template>

<script setup>
import { computed, ref, defineModel, watch } from 'vue'
import BaseModal from './BaseModal.vue'
import BaseButton from '@/components/general/BaseButton.vue'
import SquirclePath from '@/components/general/SquirclePath.vue'
import { $t } from '@/config/i18n'
import LoadingSpinner from '../LoadingSpinner.vue'
import { Cropper, Preview } from 'vue-advanced-cropper'
import { captureException } from '@sentry/vue'
import apiService from '@/services/api.service'
import { useAlertsStore } from '@/stores/alerts'
import ImageUploadStencil from '@/components/modals/ImageUploadStencil.vue'
import { Image as ImageJS } from 'image-js'

const alertsStore = useAlertsStore()

const props = defineProps(['uploadUrl'])
const model = defineModel({ default: false })
const emits = defineEmits(['uploadSuccessfully'])

const isLoading = ref(false)
const image = ref(null)
const file = ref(null)
const croppy = ref(null)
const zoom = ref(0.5)
const result = ref({
  coordinates: null,
  image: null
})
const errors = ref([])

watch(
  () => model.value,
  (newVal) => {
    if (!newVal) {
      closeModal(true)
    }
  }
)

function doZoom(value = 0.1) {
  zoom.value = zoom.value + value
  zoom.value = zoom.value > 0.95 ? 0.95 : zoom.value
  zoom.value = zoom.value < 0.0 ? 0.0 : zoom.value
  onZoom()
}

function onZoom() {
  const cropper = croppy.value

  if (cropper) {
    const width = croppy.value.imageSize.width
    croppy.value.zoom(
      (width -
        ((croppy.value.imageSize.width - croppy.value.coordinates.width) /
          (croppy.value.imageSize.width - croppy.value.sizeRestrictions.minWidth)) *
        width) /
      (width - zoom.value * (width - croppy.value.sizeRestrictions.minWidth))
    )
  }
}

function onChange({ coordinates, image }) {
  result.value = {
    coordinates,
    image
  }

  if (!croppy.value?.imageSize?.width) {
    console.log('croppy not found!')
    return
  }
  // TODO: Initial zoom factor?
  // TOOD: Center zoom?
  zoom.value =
    (croppy.value.imageSize.width - croppy.value.coordinates.width) /
    (croppy.value.imageSize.width - croppy.value.sizeRestrictions.minWidth)
}

function defaultSize() {
  return {
    width: 312,
    height: 312
  }
}

async function getHeightAndWidth(url) {
  return new Promise(resolve => {
    const img = new Image()
    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width
      })
    }
    img.src = url
  })
}

async function previewImage(event) {
  const { files } = event.target
  if (files && files[0]) {
    if (image.value?.src) {
      URL.revokeObjectURL(image.value.src)
    }

    const blob = URL.createObjectURL(files[0])
    const reader = new FileReader()

    const img = await getHeightAndWidth(blob)
    // Validation
    if (img.width < 312 || img.height < 312) {
      alertsStore.error($t('errors.image.cups.too_small'), 20)
      return
    }

    reader.onload = (e) => {
      image.value = {
        src: blob,
        type: getMimeType(e.target.result, files[0].type)
      }
    }
    reader.readAsArrayBuffer(files[0])
    zoom.value = 0.5
    try {
      console.log('prevZoom')
      zoom.value =
        (croppy.value.imageSize.width - croppy.value.coordinates.width) /
        (croppy.value.imageSize.width - croppy.value.sizeRestrictions.minWidth)
      console.log(zoom.value)
    } catch (error) {
      console.log(error)
    }
  }
}

function getMimeType(file, fallback = null) {
  const byteArray = new Uint8Array(file).subarray(0, 4)
  let header = ''
  for (let i = 0; i < byteArray.length; i++) {
    header += byteArray[i].toString(16)
  }
  switch (header) {
    case '89504e47':
      return 'image/png'
    case '47494638':
      return 'image/gif'
    case 'ffd8ffe0':
    case 'ffd8ffe1':
    case 'ffd8ffe2':
    case 'ffd8ffe3':
    case 'ffd8ffe8':
      return 'image/jpeg'
    default:
      return fallback
  }
}

async function uploadImage() {
  isLoading.value = true

  try {
    let imageBlob = null
    if (image.value) {
      const { canvas } = croppy.value.getResult()
      imageBlob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg'))
      // console.log('Test', imageBlob)
    }
    const form = new FormData()
    form.append('image', imageBlob)
    let response = await apiService.create(props.uploadUrl, form)
    isLoading.value = false
    if (response.success) {
      emits('uploadSuccessfully', response.data)
      alertsStore.success($t('successfully.upload_image'))
      closeModal()
    } else {
      alertsStore.error($t('errors.ups_upload_image'))
    }
  } catch (error) {
    if (apiService.checkIfNotAuthenticated(error)) {
      return
    } else if (
      error?.response?.status == 403 &&
      error?.response?.data?.message == $t('errors.no_subscription')
    ) {
      alertsStore.error($t('errors.no_subscription'))
    } else if (apiService.checkIfServerError(error)) {
      //
    } else if (error?.response?.status == 422) {
      errors.value = apiService.convertValidationErrors(error)
    } else {
      alertsStore.error($t('errors.ups'))
      console.log(error)
      captureException(error)
    }
  }
  isLoading.value = false
}

function closeModal(withoutModel = false) {
  isLoading.value = false
  image.value = null
  file.value = null
  croppy.value = null
  zoom.value = 0
  errors.value = []
  if (withoutModel) {
    return
  }
  model.value = false
}
</script>
