import { captureException, captureMessage } from '@sentry/vue'
import { now } from '@vueuse/core'
import axios from 'axios'
import { hex } from 'color-convert'

class TimelinkStore {
  updateTl(entry) {
    if (
      !Object.hasOwn(entry, 'tl') ||
      !Object.hasOwn(entry.tl, 'isDirty') ||
      !Object.hasOwn(entry.tl, 'origin') ||
      entry.tl.origin == null
    ) {
      return
    }

    for (const [key] of Object.entries(entry)) {
      if (key == 'tl' || key == 'gates') {
        continue
      }
      if (key in (entry.tl.ignoreKeys ?? [])) {
        continue
      }
      if (entry[key] != entry.tl.origin[key]) {
        entry.tl.isDirty = true
        return
      }
    }
    entry.tl.isDirty = false
  }
  removeSearchFlag(entry) {
    if (
      entry == undefined ||
      entry == null ||
      !Object.hasOwn(entry, 'tl') ||
      !Object.hasOwn(entry.tl, 'search')
    ) {
      return
    }
    entry.tl.search = false
  }
  getTempId() {
    return 'temp-' + now()
  }
  isTempId(id) {
    if (id == undefined || id == null) {
      return false
    }
    try {
      return id.toString().startsWith('temp-')
    } catch (error) {
      captureException(error, [id])
    }
    return false
  }

  generateId() {
    return Date.now() + '' + Math.round(Math.random() * 100)
  }

  fetchImage(imageUrl, callback, obj = null) {
    axios
      .get(import.meta.env.VITE_API_URL + '/api/v1/img/' + imageUrl)
      .then((res) => {
        callback(res.data)
      })
      .catch((error) => {
        if (error?.response?.status == 404) {
          return
        }
        console.error(error)
      })
  }


  colors = [
    '#334155',
    '#3f3f46',
    '#44403c',
    '#c2410c',
    '#b45309',
    '#a16207',
    '#4d7c0f',
    '#15803d',
    '#047857',
    '#0f766e',
    '#0e7490',
    '#0369a1',
    '#1d4ed8',
    '#4338ca',
    '#6d28d9',
    '#7e22ce',
    '#a21caf',
    '#be185d',
    '#be123c'
  ]

  getRandomColorFromPreset() {
    return this.colors[Math.round(Math.random() * this.colors.length) % this.colors.length] ?? '#D1D973'
  }

  /**
   *
   * @param {*} obj
   * @returns
   */
  generateBackgroundColor(obj = { name: null, acronym: null, id: null }) {
    if (obj == undefined || obj == undefined) {
      return Math.round(Math.random() * this.colors.length) % this.colors.length
    }
    const colorsLength = this.colors.length
    let text = obj.name
    if (obj.acronym && obj.acronym != '') {
      text = obj.acronym
    }
    if (text == undefined || text == null) {
      text = ' '
    }
    let number = 0
    for (let i = 0; i < (text ?? ' ').length; ++i) {
      number += text.charCodeAt(i) % (26 + 26 + 10 + 3)
      // if (number > 1000) {
      //   number = number % colorsLength
      // }
    }

    let id = obj.id ?? ' '
    for (let i = 0; i < (id ?? ' ').length; ++i) {
      number += id.charCodeAt(i) % (26 + 26 + 10 + 3)
    }

    return this.colors.at(number % colorsLength)
  }

  generateForegroundColor(backgroundColor) {
    let bgHsl = hex.hsl(backgroundColor)

    return bgHsl[2] > 50 ? '#000000' : '#ffffff'
  }

  acronymOrShortName(obj) {
    if (!obj) {
      return ''
    }
    let text = ''
    if (obj.acronym && obj.acronym != '') {
      text = obj.acronym
    } else {
      text = (obj.name ?? obj.full_name ?? '')
        .split(' ')
        .map((item) => item.substring(0, 1))
        .join('')
    }
    text = text.substring(0, 6)
    return text
  }

  removeError(entry) {
    entry.tl.error = null
    entry.tl.error_code = null
  }

  static timeoutHandler = []
  static intervalHandler = []
  static watchHandler = []
  setOrRenewTimeout(type, name, callback, timeout) {
    this.removeTimeout(type, name)
    if (!(TimelinkStore.timeoutHandler[type] ?? null)) {
      TimelinkStore.timeoutHandler[type] = {}
    }
    if (typeof callback != 'function') {
      console.trace()
      captureMessage('Callback is not a function', [])
      return
    }
    TimelinkStore.timeoutHandler[type][name] = setTimeout(() => {
      callback()
    }, timeout)
    return TimelinkStore.timeoutHandler[type][name]
  }
  removeTimeout(type, name) {
    if (!(TimelinkStore.timeoutHandler[type] ?? null)) {
      TimelinkStore.timeoutHandler[type] = {}
    }
    if (TimelinkStore.timeoutHandler[type][name] ?? null) {
      clearTimeout(TimelinkStore.timeoutHandler[type][name])
    }
  }
  removeAllTimeoutsFrom(type) {
    if (!(TimelinkStore.timeoutHandler[type] ?? null)) {
      TimelinkStore.timeoutHandler[type] = {}
    }
    if (TimelinkStore.timeoutHandler[type] ?? null) {
      Object.values(TimelinkStore.timeoutHandler[type]).forEach((item) => {
        clearTimeout(item)
      })
    }
  }
  setOrRenewInterval(type, name, callback, timeout) {
    this.removeInterval(type, name)
    if (!(TimelinkStore.intervalHandler[type] ?? null)) {
      TimelinkStore.intervalHandler[type] = {}
    }
    TimelinkStore.intervalHandler[type][name] = setInterval(() => {
      callback()
    }, timeout)
    return TimelinkStore.intervalHandler[type][name]
  }
  removeInterval(type, name) {
    if (!(TimelinkStore.intervalHandler[type] ?? null)) {
      TimelinkStore.intervalHandler[type] = {}
    }
    if (TimelinkStore.intervalHandler[type][name] ?? null) {
      clearInterval(TimelinkStore.intervalHandler[type][name])
    }
  }
  removeAllIntervalsFrom(type) {
    if (!(TimelinkStore.intervalHandler[type] ?? null)) {
      TimelinkStore.intervalHandler[type] = {}
    }
    if (TimelinkStore.intervalHandler[type] ?? null) {
      Object.values(TimelinkStore.intervalHandler[type]).forEach((item) => {
        clearInterval(item[1])
      })
    }
  }
  setOrRenewWatcher(type, name, watcher) {
    if (!(TimelinkStore.watchHandler[type] ?? null)) {
      TimelinkStore.watchHandler[type] = {}
    }

    this.removeWatcher(type, name)
    TimelinkStore.watchHandler[type][name] = watcher
  }
  removeWatcher(type, name) {
    if (!(TimelinkStore.watchHandler[type] ?? null)) {
      TimelinkStore.watchHandler[type] = {}
    }
    if (TimelinkStore.watchHandler[type][name] ?? null) {
      TimelinkStore.watchHandler[type][name]()
      TimelinkStore.watchHandler[type][name] = null
    }
  }

  removeAllWatcher(type) {
    if (!(TimelinkStore.watchHandler[type] ?? null)) {
      TimelinkStore.watchHandler[type] = {}
    }
    Object.keys(TimelinkStore.watchHandler[type]).forEach((key) => {
      if (TimelinkStore.watchHandler[type][key] ?? null) {
        TimelinkStore.watchHandler[type][key]()
        TimelinkStore.watchHandler[type][key] = null
      }
    })
  }

  // Deprecated
  range(start, end) {
    return new Array(end - start).fill().map((item, i) => i + start)
  }
  // Deprecated
  getPaginationRange(page, max_pages) {
    if (max_pages < 6) {
      return new Array(max_pages).fill().map((item, i) => i + 1)
    }
    let start = this.range(1, 4)

    let middle = []
    let end = this.range(max_pages - 2, max_pages + 1)

    if (page > 1 && page < max_pages) {
      middle = new Array(3)
        .fill()
        .map((item, i) => i + page - 1)
        .filter((item) => !start.includes(item) && !end.includes(item))
    }
    if (middle.length == 0) {
      middle = ['...']
    } else {
      if (middle[0] - 1 != start[2]) {
        middle.unshift('...')
      }
      if (middle[middle.length - 1] + 1 != end[0]) {
        middle.push('...')
      }
    }

    return [...start, ...middle, ...end]
  }
}

export default new TimelinkStore()
