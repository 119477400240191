<template>
  <div class="flex flex-row items-center py-2 pl-2 pr-6 group">
    <div class="flex items-center justify-center w-12 h-12 mr-4">
      <div class="flex items-center justify-center w-10 h-10 aspect-square squircle-clip" :style="{
        color: !item.image_id
          ? timelinkService.generateForegroundColor(
            item.color ? item.color : timelinkService.generateBackgroundColor(item)
          )
          : null,
        backgroundColor: !item.image_id
          ? item.color
            ? item.color
            : timelinkService.generateBackgroundColor(item)
          : null
      }">
        <img v-if="item.image_id && imagesStore.has(item.image_id)" :src="imagesStore.get(item.image_id)"
          class="w-48 squircle-clip aspect-square roboto-regular" />
        <span class="text-lg font-bold" v-else v-text="timelinkService.acronymOrShortName(item)"></span>
      </div>
    </div>

    <div class="flex flex-col">
      <div>
        <div class="font-medium" :class="{
          'text-mossgray-500 line-through ': !item.active
        }">
          <router-link :to="{ name: 'ProjectEdit', params: { project_id: item.id } }"
            class="flex items-center gap-2 leading-5">
            {{ item.name }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import timelinkStoresService from '@/services/timelink-stores.service'
import { useImagesStore } from '@/stores/images';
// vue default export
export default {
  props: ['project'],
  components: {},
  setup() {
    const timelinkService = timelinkStoresService
    const imagesStore = useImagesStore()
    return {
      timelinkService,
      imagesStore
    }
  },
  created() {
    this.item = { ...this.project }
  },
  mounted() {
    this.$echo
      .private('project.' + this.item.id)
      .listen('ProjectUpdated', (payload) => {
        this.item = { ...this.item, ...payload.project }
      })
      .listen('.project.updated', (payload) => {
        this.item = { ...this.item, ...payload.project }
      })
  },
  beforeUnmount() {
    this.$echo.leave('project.' + this.item.id)
  },
  data() {
    return {
      item: {}
    }
  },
  computed: {},
  methods: {}
}
</script>
