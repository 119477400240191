<template>
  <div class="flex flex-col justify-center w-full h-full min-h-screen p-4 dragBody">
    <form action="#" @submit.prevent="">
      <h1 class="mb-3 text-2xl font-bold w-fit">{{ $t('overlay.header') }}</h1>
      <textarea v-model="description" :disabled="isLoading"
        class="block w-full mb-3 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-mossgray placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-mossgray sm:text-sm sm:leading-6"
        :placeholder="$t('overlay.textarea_placeholder')" autofocus ref="textInput"></textarea>
      <div class="text-end">
        <button class="button-mossgray" @click="saveAndClose()">

          <span>
            {{ $t('save') }}
          </span>
        </button>
      </div>
    </form>
  </div>
</template>

<style type="text/css">
.dragBody {
  -webkit-app-region: drag;
}

h1,
textarea,
button {
  -webkit-app-region: no-drag;
}
</style>

<script setup>
import desktopService from '@/services/desktop.service'
import timelinkStoresService from '@/services/timelink-stores.service'
import { useAuthUserStore } from '@/stores/auth-user'
import { useTimeEntryStore } from '@/stores/timeEntry'
import { useOverlayWindowStore } from '@/stores/overlay-window'
import { nextTick, onBeforeUnmount, onMounted, ref, useTemplateRef } from 'vue'
import { $t } from '@/config/i18n'
const overlayWindowStore = useOverlayWindowStore()
const authUserStore = useAuthUserStore()
const timeEntryStore = useTimeEntryStore()

const isLoading = ref(true)
const userName = ref(null)
const description = ref(null)
const foo = ref('bar')
const lastState = ref(null)
const isSaving = ref(false)
const platform = ref('')
const input = useTemplateRef('textInput')

async function focusInput() {
  await nextTick()
  input.value.focus()
}

function blur() {
  lastState.value = 'blur'
  saveAndClose()
}
function focus() {
  if (lastState.value == 'blur') {
    isSaving.value = false
    isLoading.value = true
    window.location.reload()
  }
}
function closeKeyEvent(event) {
  if (event.key == 'Escape') {
    close()
  }
}
function saveAndClose() {
  if (isSaving.value) {
    return
  }
  isSaving.value = true
  if (!timeEntryStore.activeTimeEntry) {
    close()
  }
  timeEntryStore.getActiveTimeEntry.description = description.value
  timelinkStoresService.updateTl(timeEntryStore.getActiveTimeEntry)
  timeEntryStore.updateId(timeEntryStore.activeTimeEntry)
  overlayWindowStore.last_push = Date.now()
  close()
}
function close() {
  description.value = null
  desktopService.setStatus('overlayWindowActive', false)
  isLoading.value = true
}
/**
 *
 * @param {KeyboardEvent} event
 */
function saveKeyEvent(event) {
  if ((event.metaKey || event.ctrlKey) && (event.key == 'Enter' || event.code == 13)) {
    saveAndClose()
    return false
  }
}

onMounted(() => {
  platform.value = navigator.platform
  // platform.value = 'Mac ARM'
  userName.value = authUserStore.user.first_name
  description.value = timeEntryStore.getActiveTimeEntry?.description
  window.addEventListener('keyup', closeKeyEvent)
  window.addEventListener('keydown', saveKeyEvent)
  window.addEventListener('focus', focus)
  window.addEventListener('blur', blur)
  isLoading.value = false

  if (timeEntryStore.getActiveTimeEntry == null) {
    close()
  }
  setTimeout(() => focusInput, 20)
})

onBeforeUnmount(() => {
  window.removeEventListener('keyup', closeKeyEvent)
  window.removeEventListener('keydown', saveKeyEvent)
  window.removeEventListener('focus', focus)
  window.removeEventListener('blur', blur)
})
</script>
