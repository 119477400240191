<template>
  <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
  <div class="flex flex-col h-full min-h-screen px-6 pb-6 site-background basis-full" v-if="!isOffline">
    <div class="flex items-center w-full py-3 mb-3">
      <h1 class="mr-auto text-2xl font-bold">
        {{ $t('service', 2) }}
      </h1>
      <div class="flex flex-row items-center gap-4">
        <BaseSwitch label="hideDisabled" v-model="hideDisabled">
          {{ $t('settings.services.hide_deactivated') }}
        </BaseSwitch>
        <div class="flex space-x-2">
          <input type="text" id="search" name="search" v-model="search" class="input" :placeholder="$t('search')" />
        </div>
        <div class="flex flex-row pl-3 space-x-4">
          <span v-if="saveComplete" class="pb-1 text-lg font-bold text-green-500 border-b-4 border-green-500 md:ml-4">{{
            $t('success_message') }}</span>
          <BaseButton class="button-mossgray" @click="showCreateModal = true"
            :disabled="companyStore.company.pull_provider" :only-active-sub="true">
            <font-awesome-icon :icon="['fa-kit', 'tl-plus']" class="mr-2 text-lg" /> {{ $t('new_service') }}
          </BaseButton>
        </div>
      </div>
    </div>


    <div class="overflow-x-auto">
      <!-- TODO: Translation -->
      <Alert v-if="services.length == 0">Keine Leistungen vorhanden.</Alert>

      <div class="border rounded-lg border-mossgray-300" v-if="services.length > 0">
        <div class="bg-white divide-y rounded-t-lg divide-mossray-300">
          <template v-for="service in services" :key="service.id">
            <ServicesListElement :service="service" />
          </template>
        </div>

        <div
          class="flex items-center justify-between px-4 py-3 bg-white border-t rounded-b-lg border-mossgray-200 sm:px-6">
          <div class="flex justify-between flex-1 sm:hidden">
            <a href="#" class="button button-gray">{{ $t('previous') }}</a>
            <a href="#" class="button button-gray">{{ $t('next') }}</a>
          </div>
          <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                {{ $t('pagination_showing') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.from }}</span>
                {{ ' ' }}
                {{ $t('pagination_to') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.to }}</span>
                {{ ' ' }}
                {{ $t('pagination_of') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.total }}</span>
                {{ ' ' }}
                {{ $t('pagination_total') }}
              </p>
            </div>
            <div>
              <input type="number" class="input" min="1" :max="pagination.last_page" v-model="page" @change="fetch" />
            </div>
            <div>
              <nav class="inline-flex -space-x-px rounded-md shadow-sm isolate" aria-label="Pagination">
                <button type="button" @click="previous"
                  class="relative inline-flex items-center px-2 py-2 pl-3 rounded-l-full text-mossgray-800 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0">
                  <span class="sr-only">{{ $t('previous') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-left']" fixed-width />
                </button>

                <template v-for="page in pagination.links" :key="page.label">
                  <button :class="{
                    'relative z-10 inline-flex items-center bg-mossgray px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mossgray':
                      page == page.label,
                    'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0':
                      page != page.label
                  }" type="button" :disabled="page.label == '...'" @click="gotoPage(parseInt(page.label))">
                    {{ page.label }}
                  </button>
                </template>

                <button type="button" @click="next"
                  class="relative inline-flex items-center px-2 pr-3 rounded-r-full text-mossgray-800 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0">
                  <span class="sr-only">{{ $t('next') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-right']" fixed-width />
                </button>
              </nav>
            </div>

          </div>
        </div>
      </div>
    </div>
    <ServicesCreate v-model="showCreateModal" />
  </div>
  <SquirclePath></SquirclePath>
</template>

<script setup>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import apiService from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import ServicesCreate from '@/components/settings/ServicesCreate.vue'
import { useCompanyStore } from '@/stores/company'
import ServicesListElement from '@/components/settings/ServicesListElement.vue'
import { useAuthUserStore } from '@/stores/auth-user'
import { captureException } from '@sentry/vue'
import Alert from '@/components/general/AlertComponent.vue'
import BaseSwitch from '@/components/general/BaseSwitch.vue'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import SquirclePath from '@/components/general/SquirclePath.vue'

const timelinkService = timelinkStoresService
const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const route = useRoute()
const router = useRouter()

const isLoading = ref(true)
const services = ref([])
const saveComplete = ref(null)
const limit = ref(10)
const pagination = ref({
  current_page: 1,
  from: 1,
  last_page: 1,
  links: [],
  path: '',
  per_page: 10,
  to: 1,
  total: 1
})
const showCreateModal = ref(false)
const search = ref('')
const isOffline = ref(false)
const hideDisabled = ref(true)

onMounted(() => {
  fetch()
  window.echo
    .private('company.' + useAuthUserStore().user.company_id)
    .listen('ServiceCreated', () => {
      fetch(true)
    })
    .listen('.service.created', () => {
      fetch(true)
    })
})

onBeforeUnmount(() => {
  unregisterEcho()

})

watch(() => search.value, (newVal) => {
  timelinkService.setOrRenewTimeout(
    'servicesList',
    'search',
    () => {
      if (page.value != 1) {
        page.value = 1
      } else {
        fetch()
      }
    },
    300
  )
})

watch(() => showCreateModal.value, (newVal) => {
  if (!newVal) {
    fetch(true)
  }
})

watch(() => hideDisabled.value, () => {
  fetch()
})

const page = computed(
  {
    get: () => {
      return parseInt(route.query.page ?? 1)
    },
    set: (value) => {
      value = parseInt(value)
      router.replace({ name: 'ServicesList', query: { ...route.query, page: value } })
    }
  }
)

async function fetch(withoutLoading = false) {
  if (!withoutLoading) {
    isLoading.value = true
  }
  unregisterEcho()
  try {
    let data = await apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/services', {
      limit: limit.value,
      page: page.value,
      search: search.value,
      orders: [
        {
          column: 'name',
          direction: 'asc'
        }
      ],
      ...(hideDisabled.value ? { active: true } : {})
    })
    pagination.value = { ...data.meta }
    pagination.value.links = pagination.value.links.filter(
      (item) => !item.label.includes('Previous') && !item.label.includes('Next')
    )
    services.value = data.data
    if (!withoutLoading) {
      isLoading.value = false
    }
    registerEcho()
  } catch (error) {
    captureException(error)
    //
  }
}
function unregisterEcho() {
  services.value.forEach((item) => {
    window.echo.leave('service.' + item.id)
  })
}
function registerEcho() {
  services.value.forEach((item) => {
    window.echo.private('service.' + item.id).listen('ServiceDeleted', () => {
      fetch()
    })
  })
}
function next() {
  if (page.value >= pagination.value.last_page) {
    return
  }
  page.value += 1
  // fetch()
}
function previous() {
  if (page.value <= 1) {
    return
  }
  page.value -= 1
  // fetch()
}
function gotoPage(newPage = 1) {
  if (newPage < 1) {
    page.value = 1
    // router.replace({ name: 'ServicesList', query: { ...route.query, page: 1 } })
  } else if (newPage > pagination.value.last_page) {
    page.value = pagination.value.last_page
    // router.replace({
    //   name: 'ServicesList',
    //   query: { ...route.query, page: pagination.value.last_page }
    // })
  } else {
    page.value = newPage
    // router.replace({ name: 'ServicesList', query: { ...route.query, page: page } })
  }
  // fetch()
}
</script>
