<template>
  <loading-spinner v-model="isLoading" :fullPage="true" />
  <div class="flex flex-col h-full min-h-screen px-6 pb-6 site-background basis-full" v-if="!isOffline">
    <div class="flex items-center w-full py-3 mb-3">
      <h1 class="mr-auto text-2xl font-bold">
        {{ $t('menu.settings_.clients_and_projects') }}
      </h1>

      <div class="flex flex-row items-center gap-4">
        <BaseSwitch label="hideDisabled" v-model="hideDisabled">
          {{ $t('settings.clients.hide_deactivated') }}
        </BaseSwitch>
        <div class="flex space-x-2">
          <input type="text" id="search" name="search" v-model="search" class="input" :placeholder="$t('search')" />
        </div>
        <div class="flex flex-row space-x-4">
          <BaseButton class="button-mossgray" @click="showCreateModal = true" :only-active-sub="true"
            :disabled="Boolean(companyStore.company.pull_provider)" v-if="authUserStore.user.admin >= 9">
            <font-awesome-icon :icon="['fa-kit', 'tl-plus']" class="mr-2 text-lg" /> {{ $t('new_client') }}
          </BaseButton>
        </div>
      </div>
    </div>

    <div class="overflow-x-auto">
      <!-- TODO: Translation -->
      <Alert v-if="clients.length == 0">Keine Kunden vorhanden.</Alert>

      <div class="border rounded-lg border-mossgray-200" v-if="clients.length > 0">
        <div class="bg-white divide-y rounded-t-lg divide-mossray-300">
          <template v-for="client in clients" :key="client.id">
            <ClientsListElement :client="client" />
          </template>
        </div>

        <div
          class="flex items-center justify-between px-4 py-3 bg-white border-t rounded-b-lg border-mossgray-200 sm:px-6">
          <div class="flex justify-between flex-1 sm:hidden">
            <a href="#" class="button button-gray">{{ $t('previous') }}</a>
            <a href="#" class="button button-gray">{{ $t('next') }}</a>
          </div>
          <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                {{ $t('pagination_showing') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.from }}</span>
                {{ ' ' }}
                {{ $t('pagination_to') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.to }}</span>
                {{ ' ' }}
                {{ $t('pagination_of') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.total }}</span>
                {{ ' ' }}
                {{ $t('pagination_total') }}
              </p>
            </div>
            <div>
              <input type="number" class="input" min="1" :max="pagination.last_page" v-model="page" @change="fetch" />
            </div>
            <div>
              <nav class="inline-flex -space-x-px rounded-md shadow-sm isolate" aria-label="Pagination">
                <button type="button" @click="previous"
                  class="relative inline-flex items-center px-2 py-2 pl-3 rounded-l-full text-mossgray-800 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0">
                  <span class="sr-only">{{ $t('previous') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-left']" fixed-width />
                </button>

                <template v-for="item in pagination.links" :key="item.label">
                  <button :class="{
                    'relative z-10 inline-flex items-center bg-mossgray px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mossgray':
                      page == item.label,
                    'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0':
                      page != item.label
                  }" type="button" :disabled="item.label == '...'" @click="gotoPage(parseInt(item.label))">
                    {{ item.label }}
                  </button>
                </template>

                <button type="button" @click="next"
                  class="relative inline-flex items-center px-2 py-2 pr-3 rounded-r-full text-mossgray-800 ring-1 ring-inset ring-mossgray-200 hover:bg-mossgray-50 focus:z-20 focus:outline-offset-0">
                  <span class="sr-only">{{ $t('next') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-right']" fixed-width />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ClientsCreate v-model="showCreateModal" />
  </div>
  <!-- TODO: Translation -->
  <div v-else>Du bist <u>offline!</u></div>

  <SquirclePath></SquirclePath>
</template>

<script setup>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import SquirclePath from '@/components/general/SquirclePath.vue'
import apiService from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import Alert from '@/components/general/AlertComponent.vue'
import timelinkStoresService from '@/services/timelink-stores.service'
import ClientsCreate from '@/components/settings/ClientsCreate.vue'
import { useCompanyStore } from '@/stores/company'
import ClientsListElement from '@/components/settings/ClientsListElement.vue'
import { useAuthUserStore } from '@/stores/auth-user'
import { useAlertsStore } from '@/stores/alerts'
import { captureException } from '@sentry/vue'
import BaseSwitch from '../general/BaseSwitch.vue'
import { $t } from '@/config/i18n'
import { computed, onBeforeMount, onBeforeUnmount, onMounted, ref, watch, watchEffect } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useImagesStore } from '@/stores/images'

const timelinkService = timelinkStoresService
const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const imagesStore = useImagesStore()

const route = useRoute()
const router = useRouter()


const isLoading = ref(true)
const clients = ref([])
const saveComplete = ref(null)
const errorMessage = ref(null)
// page: 1,
const limit = ref(10)
const pagination = ref({
  current_page: 1,
  from: 1,
  last_page: 1,
  links: [],
  path: '',
  per_page: 10,
  to: 1,
  total: 1
})
const showCreateModal = ref(false)
const search = ref('')
const isOffline = ref(false)
const hideDisabled = ref(true)

const page = computed({
  get: () => {
    return parseInt(route.query.page ?? 1)
  },
  set: (value) => {
    value = parseInt(value)
    router.replace({
      name: 'ClientsList',
      query: { ...route.query, page: value }
    })
  }
})

// recursive calls not working yet.
// watchEffect(() => {
//   fetch()
// })

watch(() => limit.value, (newVal) => {
  if (page.value != 1) {
    page.value = 1
    return
  }
  fetch()
})

watch(() => page.value, (newVal) => {
  fetch()
  timelinkService.removeTimeout('clientsList', 'search')
})

watch(() => search.value,
  () => {
    timelinkService.setOrRenewTimeout(
      'clientsList',
      'search',
      () => {
        if (page.value != 1) {
          page.value = 1
        } else {
          fetch()
        }
      },
      300
    )
  }
)

watch(() => showCreateModal.value, (newVal) => {
  if (!newVal) {
    fetch(true)
  }
})
watch(() => hideDisabled.value, () => {
  page.value = 1
  fetch()
})

onMounted(() => {
  fetch()
  window.echo
    .private('company.' + authUserStore.user.company_id)
    .listen('ClientCreated', () => {
      fetch(true)
    })
    .listen('.client.created', () => {
      fetch(true)
    })
})

onBeforeUnmount(() => {
  unregisterEcho()
  console.log('leave channel')
  window.echo.leave('company.' + authUserStore.user.company_id)
})


async function fetch(withoutLoading = false) {
  if (!withoutLoading) {
    isLoading.value = true
  }
  unregisterEcho()
  try {
    let data = await apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/clients', {
      limit: limit.value,
      page: page.value,
      search: search.value,
      ...(hideDisabled.value ? {active: true}: {}),
      orders: [
        {
          column: 'name',
          direction: 'asc'
        }
      ]
    })
    pagination.value = { ...data.meta }
    pagination.value.links = pagination.value.links.filter(
      (item) => !item.label.includes('Previous') && !item.label.includes('Next')
    )
    clients.value = data.data
    clients.value.forEach((item) => {
      imagesStore.fetch(item.image_id)
    })

    registerEcho()
  } catch (error) {
    if (error.code == 'ERR_NETWORK' || error.code == 'ERR_CANCELED') {
      useAlertsStore().error($t('errors.noConnection'))
    } else {
      console.log(error)
      useAlertsStore().error($t('errors.ups'))
      captureException(error)
    }
  }
  if (!withoutLoading) {
    isLoading.value = false
  }
}
function unregisterEcho() {
  clients.value.forEach((item) => {
    window.echo.leave('client.' + item.id)
  })
}
function registerEcho() {
  clients.value.forEach((item) => {
    window.echo
      .private('client.' + item.id)
      .listen('ClientDeleted', () => {
        fetch()
      })
      .listen('.client.deleted', () => {
        fetch()
      })
  })
}
function next() {
  if (page.value >= pagination.value.last_page) {
    return
  }
  page.value += 1
  // fetch()
}
function previous() {
  if (page.value <= 1) {
    return
  }
  page.value -= 1
  // fetch()
}
function gotoPage(nextPage = 1) {
  if (nextPage < 1) {
    page.value = 1
  } else if (nextPage > pagination.value.last_page) {
    page.value = pagination.value.last_page
  } else {
    page.value = nextPage
  }
}

</script>
