<template>
  <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
  <div class="flex flex-col h-full min-h-screen px-6 pb-6 site-background basis-full">
    <div class="flex items-center w-full py-3 mb-3">
      <h1 class="mr-auto text-2xl font-bold">
        {{ $t('user', 10) }}
      </h1>
      <div class="flex space-x-2">
        <input type="text" id="search" name="search" v-model="search" class="input" :placeholder="$t('search')" />
      </div>
      <div class="flex flex-row pl-3 space-x-4">
        <span v-if="saveComplete" class="pb-1 text-lg font-bold text-green-500 border-b-4 border-green-500 md:ml-4">{{
          $t('success_message') }}</span>
        <BaseButton class="button-mossgray" @click="showCreateModal = true" :disabled="!enoughLicenses"
          :only-active-sub="true">
          <font-awesome-icon :icon="['fa-kit', 'tl-plus']" class="mr-2 text-lg" /> {{ $t('new_user') }}
        </BaseButton>
      </div>
    </div>

    <div class="overflow-x-auto">
      <div class="flex flex-row pt-1 pr-1 mb-2 space-x-4"
        :class="{ 'justify-between': !enoughLicenses, 'justify-end': enoughLicenses }">
        <div v-if="!enoughLicenses">
          <span>Alle Lizenzen sind in Verwendung. Erhöhe die Anzahl der Lizenzen um einen weiteren Benutzer
            anzulegen.</span>
        </div>
        <BaseSwitch label="hideDisabled" v-model="hideDisabled">
          {{ $t('settings.users.hide_disabled_users') }}
        </BaseSwitch>
      </div>

      <!-- TODO: Translation -->
      <Alert v-if="users.length == 0">{{ $t('settings.users.list.no_entries') }}</Alert>

      <div class="border rounded-lg border-mossgray-300" v-if="users.length > 0">
        <div class="bg-white divide-y rounded-t-lg divide-mossray-300">
          <template v-for="user in users" :key="user.id">
            <UsersListElement :user="user" />
          </template>
        </div>

        <div
          class="flex items-center justify-between px-4 py-3 bg-white border-t rounded-b-lg border-mossgray-200 sm:px-6">
          <div class="flex justify-between flex-1 sm:hidden">
            <a href="#" class="button button-gray">{{ $t('previous') }}</a>
            <a href="#" class="button button-gray">{{ $t('next') }}</a>
          </div>
          <div class="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
            <div>
              <p class="text-sm text-gray-700">
                {{ $t('pagination_showing') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.from }}</span>
                {{ ' ' }}
                {{ $t('pagination_to') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.to }}</span>
                {{ ' ' }}
                {{ $t('pagination_of') }}
                {{ ' ' }}
                <span class="font-medium">{{ pagination.total }}</span>
                {{ ' ' }}
                {{ $t('pagination_total') }}
              </p>
            </div>
            <div>
              <input type="number" class="input" min="1" :max="pagination.last_page" v-model="page" @change="fetch" />
            </div>
            <div>
              <nav class="inline-flex -space-x-px rounded-md shadow-sm isolate" aria-label="Pagination">
                <button type="button" @click="previous"
                  class="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-l-full ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                  <span class="sr-only">{{ $t('previous') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-left']" fixed-width />
                </button>

                <template v-for="page in pagination.links" :key="page.label">
                  <button :class="{
                    'relative z-10 inline-flex items-center bg-mossgray px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-mossgray':
                      page == page.label,
                    'relative inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0':
                      page != page.label
                  }" type="button" :disabled="page.label == '...'" @click="gotoPage(parseInt(page.label))">
                    {{ page.label }}
                  </button>
                </template>

                <button type="button" @click="next"
                  class="relative inline-flex items-center px-2 py-2 text-gray-400 rounded-r-full ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0">
                  <span class="sr-only">{{ $t('next') }}</span>
                  <font-awesome-icon :icon="['fa-kit', 'tl-arrow-right']" fixed-width />
                </button>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <UsersCreate v-model="showCreateModal" />
  </div>
</template>

<script setup>
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import apiUser from '@/services/api.service'
import BaseButton from '@/components/general/BaseButton.vue'
import timelinkStoresUser from '@/services/timelink-stores.service'
import UsersCreate from '@/components/settings/UsersCreate.vue'
import { useCompanyStore } from '@/stores/company'
import UsersListElement from '@/components/settings/UsersListElement.vue'
import { useAuthUserStore } from '@/stores/auth-user'
import Alert from '@/components/general/AlertComponent.vue'
import BaseSwitch from '../general/BaseSwitch.vue'
import { computed, nextTick, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { $t } from '@/config/i18n'

const timelinkUser = timelinkStoresUser
const companyStore = useCompanyStore()
const authUserStore = useAuthUserStore()
const route = useRoute()
const router = useRouter()
const echo = window.echo

const isLoading = ref(true)
const users = ref([])
const saveComplete = ref(null)
// TODO: Catch if a page number could not exists.
const page = computed({
  get: () => {
    return parseInt(route.query.page ?? 1)
  },
  set: (value) => {
    if (pagination.value.last_page && value > pagination.value.last_page) {
      value = pagination.value.last_page
    }
    value = parseInt(value)
    router.replace({ name: 'UsersList', query: { ...route.query, page: value } })
  }
})
const limit = ref(10)
const pagination = ref({
  current_page: 1,
  from: 1,
  last_page: 1,
  links: [],
  path: '',
  per_page: 10,
  to: 1,
  total: 1
})
const showCreateModal = ref(false)
watch(() => showCreateModal.value, (newVal) => {
  if (!newVal) {
    fetch(true)
  }
})
const search = ref('')
watch(() => search.value, (newVal) => {
  timelinkUser.setOrRenewTimeout(
    'usersList',
    'search',
    () => {
      if (page.value != 1) {
        page.value = 1
      } else {
        fetch()
      }
    },
    300
  )
})
watch(() => route.params.page, () => {
  fetch()
})
const hideDisabled = ref(true)
watch(() => hideDisabled.value, () => {
  fetch()
})

const enoughLicenses = computed(() => {
  console.log(companyStore.company.active_users_count ?? (pagination.value.total && hideDisabled.value ? pagination.value.total : 1) ?? 1)
  const active_user = companyStore.company.active_users_count ?? (pagination.value.total && hideDisabled.value ? pagination.value.total : 1) ?? 1
  return companyStore?.company?.subscription?.trial || !companyStore.company.autoupdate_quantity && companyStore.company.licenses > (active_user)
})


onMounted(() => {
  fetch()
  echo
    .private('company.' + companyStore.company.id + '.admin')
    .listen('UserCreated', () => {
      fetch(true)
    })
    .listen('.user.created', () => {
      fetch(true)
    })
})

onBeforeUnmount(() => {
  unregisterEcho()
  echo
    .private('company.' + companyStore.company.id + '.admin')
    .stopListening('UserCreated')
    .stopListening('.user.created')
})

async function fetch(withoutLoading = false) {
  if (!withoutLoading) {
    isLoading.value = true
  }
  unregisterEcho()
  try {
    let data = await apiUser.fetch(import.meta.env.VITE_API_URL + '/api/v1/users', {
      limit: limit.value,
      page: page.value,
      search: search.value,
      hideDisabled: hideDisabled.value ? 1 : 0,
      orders: [
        {
          column: 'first_name',
          direction: 'asc'
        },
        {
          column: 'last_name',
          direction: 'asc'
        },
        {
          column: 'email',
          direction: 'asc'
        }
      ]
    })
    pagination.value = { ...data.meta }
    pagination.value.links = pagination.value.links.filter(
      (item) => !item.label.includes('Previous') && !item.label.includes('Next')
    )
    users.value = data.data
    // if (page.value > pagination.value.last_page) {
    //   await nextTick()
    //   page.value = pagination.value.last_page
    //   fetch()
    // }
    registerEcho()
  } catch (error) {
    if (error?.response?.status == 403) {
      router.push({
        name: 'General'
      })
    } else {
      throw error
    }
  }
  if (!withoutLoading) {
    isLoading.value = false
  }
}
function unregisterEcho() {
  if (authUserStore.user.admin < 9) {
    return
  }
  echo
    .private('company.' + useCompanyStore().company.id + '.admin')
    .stopListening('.user.deleted')
  // users.value.forEach((item) => {
  //   echo.leave('user.' + item.id)
  // })
}
function registerEcho() {
  if (useAuthUserStore().user.admin < 9) {
    return
  }
  echo
    .private('company.' + useCompanyStore().company.id + '.admin')
    .listen('.user.deleted', (payload) => {
      if (users.value.find((item) => item.id == payload?.userId)) {
        fetch()
      }
    })
  // users.value.forEach((item) => {
  //   echo
  //     .private('user.' + item.id)
  //     .listen('UserDeleted', () => {
  //       fetch()
  //     })
  //     .listen('.user.deleted', () => {
  //       fetch()
  //     })
  // })
}
function next() {
  if (page.value >= pagination.value.last_page) {
    return
  }
  page.value += 1
  // fetch()
}
function previous() {
  if (page.value <= 1) {
    return
  }
  page.value -= 1
  // fetch()
}
function gotoPage(inputPage = 1) {
  if (inputPage < 1) {
    page.value = 1
    // router.replace({ name: 'UsersList', query: { ...route.query, page: 1 } })
  } else if (inputPage > pagination.value.last_page) {
    page.value = pagination.value.last_page
    // router.replace({
    //   name: 'UsersList',
    //   query: { ...route.query, page: pagination.value.last_page }
    // })
  } else {
    page.value = inputPage
    // router.replace({ name: 'UsersList', query: { ...route.query, page: page } })
  }
  // fetch()
}
</script>
