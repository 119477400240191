
class ArrayLib {

    /**
     * 
     * @param {number} start - implicit
     * @param {number} end - explicit
     * @returns Array<number>
     */
    range(start, end) {
        if (start > end) {
            let temp = end
            end = start
            start = temp
        }
        return new Array(end - start).fill().map((item, i) => i + start)
    }

}

export default new ArrayLib()