import { useServicesStore } from "@/stores/services";
import Selection from "./Selection";
import { toValue } from "vue";
import ViewManager from "@/panel/ViewManager";
import { setSelectableService } from "@/panel/helper/services";
import { useCompanyStore } from "@/stores/company";
import { useTimeEntryStore } from "@/stores/timeEntry";
import { $t } from "@/config/i18n";

class Services extends Selection {
    showButtonId = 3
    showButtonTextI18n = "panel.selection.active.service"
    /**
     * 
     * @param {ViewManager} manager 
     */
    header(manager) {
        this.setClient(manager)
        this.setProject(manager)
    }

    getLastUsed(manager) {
        useServicesStore().fetchActiveCount()
        return useServicesStore().getLastUsed.filter((item) => item.active)
    }

    setSelectableItem(manager, id, item) {
        setSelectableService(manager, id, item)
    }

    /**
         * 
         * @param {ViewManager} manager 
         * @returns 
         */
    getAlphabeticCount(manager) {
        return useServicesStore().activeCount
            ? useServicesStore().activeCount
            : useCompanyStore().company.active_services_count
    }

    /**
    * @param  {ViewManager} manager
    * @returns {Array<any>}
    */
    getAlphabetic(manager) {
        return useServicesStore()
            .services.filter((item) => item.active)
            .toSorted((a, b) => a.name.localeCompare(b.name))
    }

    /**
     * 
     * @param {ViewManager} manager 
     * @param {number} page 
     * @param {any} pagination 
     * @param {any} dims 
     * @param {any} needToLoadMore 
     */
    async fetchAlphabetic(manager, page, pagination, dims, needToLoadMore) {
        useServicesStore().fetch(
            {
                page: page + 2,
                limit: dims.columns * dims.rows * 2,
                orders: [
                    {
                        column: 'name',
                        direction: 'asc'
                    }
                ]
            },
            false,
            true,
            () => {
                if (toValue(manager.subView) != 'service_id' || !pagination.next || !needToLoadMore) {
                    return
                }
                panel.setId(arr, dims.rows * dims.columns - 1, panel.getPaginationNextButton(page))
            }
        )
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    async initFetch(manager) {
        const dims = manager.getDimensions()
        useServicesStore().fetch(
            {
                limit: dims.columns * dims.rows * 2,
                orders: [
                    {
                        column: 'name',
                        direction: 'asc'
                    }
                ]
            },
            false,
            true,
            () => { }
        )
    }

    canBeNull() {
        return !(useTimeEntryStore().requiredFields ?? []).includes('service_id')
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    setNullKey(manager) {
        const dims = manager.getDimensions()

        manager.setId(dims.columns, {
            type: 'text',
            text: $t('panel.select_no_service'),
            callback: () => {
                return () => {
                    const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
                    activeTimeEntry.client_id = null
                    activeTimeEntry.project_id = null
                    timelinkStoresService.updateTl(activeTimeEntry)
                    useTimeEntryStore().delayUpdateId(activeTimeEntry.id, 1000, true)
                    manager.switchToTracking()
                }
            }
        })
    }
}

export default new Services()