<template>
  <TemplateComponent>

    <div class="flex items-center w-full py-3 mb-3">
      <h1 class="mr-auto text-2xl font-bold">
        {{ $t('data_integration', 1) }}
      </h1>
    </div>

    <div v-if="!errorLoading">
      <div v-if="!dataIntegration?.provider">
        <div class="px-2 pb-4 font-semibold">
          {{ $t('data_integrations.choose_a_provider') }}
        </div>

        <div v-if="!hasNoData" class="flex flex-col max-w-2xl pb-4">
          <AlertComponent class="text-red-500" :type="'error'">
            <template #title>{{ $t('attention') }}</template>
            <div>

              {{ $t('data_integrations.has_data.notice') }}
            </div>
            <div class="flex justify-between mt-2">
              <BaseButton class="button-mossgray" @click="showExportModal = true">
                <font-awesome-icon :icon="['fa-kit', 'tl-file-export']" class="mr-2" />
                {{ $t('settings.general.export_time_entries') }}
              </BaseButton>
              <BaseButton class=" button-red" @click="showResetModal = true" :only-active-sub="true">{{
                $t('data_integrations.has_data.delete_button') }}</BaseButton>
            </div>
          </AlertComponent>
        </div>

        <div class="grid grid-cols-1 gap-6 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-5">
          <ProviderButton :provider="'clockodo'" @set-provider="setProvider" :disabled="!hasNoData">
            <ClockodoLogo></ClockodoLogo>
          </ProviderButton>

          <ProviderButton :provider="'awork'" @set-provider="setProvider" :disabled="!hasNoData">
            <img src="https://cdn.prod.website-files.com/6418f5bfe5bc0a3438109c1d/641ccf4e9454867f1de9006f_logo.svg"
              class="" />
          </ProviderButton>
        </div>

      </div>

      <div class="pb-16 space-y-8 divide-y divide-mossgray-200" v-else>
        <div class="grid grid-cols-1 gap-x-8 gap-y-8 md:grid-cols-3">
          <div class="px-4 py-4 sm:px-4">
            <ClockodoLogo v-if="dataIntegration.provider == 'clockodo'"></ClockodoLogo>
            <AworkLogo v-if="dataIntegration.provider == 'awork'"></AworkLogo>
          </div>

          <form class="bg-white ring-1 ring-mossgray-200 sm:rounded-xl md:col-span-2" ref="form" @submit.prevent="save">
            <div class="px-4 py-6 sm:p-8">
              <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="sm:col-start-1 sm:col-span-3">
                  <label for="token" class="block text-sm font-medium leading-6">{{
                    $t('token')
                  }}</label>
                  <div class="mt-2">
                    <input type="text" name="token" id="token" v-model="dataIntegration.token" class="w-full input" />
                  </div>
                  <div class="text-sm text-red-500" v-if="errors.token">
                    <template v-for="message in errors.token" :key="message">
                      <div>
                        {{ message }}
                      </div>
                    </template>
                  </div>
                </div>

                <div class="sm:col-span-3" v-if="dataIntegration.provider == 'clockodo'">
                  <label for="username" class="block text-sm font-medium leading-6 text-gray-900">{{
                    $t('username')
                  }}</label>
                  <div class="mt-2">
                    <input id="username" name="username" type="username" autocomplete="username"
                      v-model="dataIntegration.username" class="w-full input" />
                  </div>
                </div>
              </div>
            </div>

            <div class="flex items-center px-4 py-4 border-t gap-x-6 border-gray-900/10 sm:px-8"
              :class="{ 'justify-end': !setupWizard, 'justify-between': setupWizard }">
              <div v-if="setupWizard">
                <BaseButton class="button button-mossgray" @click="backToInit">{{
                  $t('cancel')
                }}</BaseButton>
              </div>
              <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
              <BaseButton type="submit" class="button-mossgray" :only-active-sub="true"><span
                  v-if="dataIntegration?.exists">
                  {{ $t('save') }}
                </span><span v-else>{{ $t('add') }}</span></BaseButton>
            </div>
          </form>
        </div>
        <div class="grid grid-cols-1 pt-6 gap-x-8 gap-y-8 md:grid-cols-3" v-if="dataIntegration?.exists ?? false">
          <div class="px-4 sm:px-0">
            <h2 class="text-base font-semibold leading-7">
              {{ $t('data_integrations.part_two_header') }}
            </h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">
              <!-- {{ $t('data_integrations.part_one_description') }} -->
            </p>
          </div>

          <div class="bg-white shadow-sm ring-1 ring-mossgray-200 sm:rounded-xl md:col-span-2">
            <div class="px-4 py-6 sm:p-8">
              <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div class="sm:col-span-3">
                  <span class="block text-sm font-medium leading-6 text-gray-900">
                    {{ $t('data_integrations.last_started') }}
                  </span>
                  <div class="mt-2">
                    <span class="font-bold">
                      {{
                        dataIntegration.last_queued
                          ? new Date(Date.parse(dataIntegration.last_queued)).toLocaleString()
                          : '-'
                      }}
                    </span>
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <span class="block text-sm font-medium leading-6">
                    {{ $t('data_integrations.last_sucessful_execution') }}
                  </span>
                  <div class="mt-2">
                    <span class="font-bold">
                      {{
                        dataIntegration.last_execution
                          ? new Date(Date.parse(dataIntegration.last_execution)).toLocaleString()
                          : '-'
                      }}
                    </span>
                  </div>
                </div>
                <div class="sm:col-span-3">
                  <span class="block text-sm font-medium leading-6">
                    {{ $t('data_integrations.next_planned_execution') }}
                  </span>
                  <div class="mt-2">
                    <span class="font-bold">
                      {{
                        dataIntegration.next_execution
                          ? new Date(Date.parse(dataIntegration.next_execution)).toLocaleString()
                          : '-'
                      }}
                    </span>
                  </div>
                </div>
                <div class="flex items-end sm:col-span-3">
                  <BaseButton :disabled="!dataIntegration.trigger_url" class="button-mossgray" type="button"
                    :only-active-sub="true" @click="startSync">
                    {{ $t('data_integrations.start_synchronization') }}
                  </BaseButton>
                </div>

                <div class="flex items-end sm:col-span-6" v-if="sync.status != null">
                  <div class="p-2 px-4 font-semibold text-green-700 bg-green-100 border-l-4 border-green-700 min-w-96"
                    v-if="sync.status">
                    <span>{{ $t('data_integrations.synchronization_started') }}</span>
                  </div>
                  <div class="p-2 px-4 font-semibold text-red-700 bg-red-100 border-l-4 border-red-700 min-w-96" v-else>
                    <span v-text="sync.message"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <AlertComponent :type="'error'">
        {{ $t('errors.noConnectionRetryLater') }}
      </AlertComponent>

    </div>
    <BaseModal v-model="showResetModal" @close-modal="showResetModal = false">
      <template #header> {{ $t('confirm_deletion') }} </template>

      <div class="p-6">
        <span>
          {{ $t('data_integrations.delete_modal.description') }}
        </span>
      </div>
      <!-- TODO: Check why it is not coming up -->
      <loading-spinner v-model="isLoadingResetData" :fullPage="true" :over-all="true"></loading-spinner>


      <template #footer>
        <div class="flex justify-end w-full gap-4">
          <BaseButton class="button-gray" type="button" @click="showResetModal = false">
            {{ $t('cancel') }}</BaseButton>
          <BaseButton class="button-red" @click="resetAllData" :disabled="!timer || timer.seconds > 0">
            <span class="w-6" v-if="timer && timer.seconds > 0"> ({{ timer.seconds }}) </span>{{ $t('delete') }}
          </BaseButton>
        </div>
      </template>
    </BaseModal>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>
    <ExportModal v-model="showExportModal"></ExportModal>

  </TemplateComponent>
</template>

<script setup>
import apiService from '@/services/api.service'
import { useAuthUserStore } from '@/stores/auth-user'
import featureFlagsService from '@/services/feature-flags.service'
import BaseButton from '@/components/general/BaseButton.vue'
import { useCompanyStore } from '@/stores/company'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import axios from 'axios'
import { captureException } from '@sentry/vue'
import { useAlertsStore } from '@/stores/alerts'
import TemplateComponent from '@/components/settings/TemplateComponent.vue'
import { onBeforeUnmount, onMounted, ref, toRaw, watch } from 'vue'
import ProviderButton from './integrations/ProviderButton.vue'
import { $t } from '@/config/i18n'
import AlertComponent from '../general/AlertComponent.vue'
import BaseModal from '../modals/BaseModal.vue'
import { useTimer } from 'vue-timer-hook'
import { useClientsStore } from '@/stores/clients'
import { useServicesStore } from '@/stores/services'
import ExportModal from '../modals/ExportModal.vue'
import { useNotificationsStore } from '@/stores/notifications'
import ClockodoLogo from './provider/ClockodoLogo.vue'
import AworkLogo from './provider/AworkLogo.vue'

const authUserStore = useAuthUserStore()
const featureFlags = featureFlagsService
const companyStore = useCompanyStore()
const alertsStore = useAlertsStore()
const clientsStore = useClientsStore()
const servicesStore = useServicesStore()
const notificationsStore = useNotificationsStore()

const errorLoading = ref(false)
const isLoading = ref(false)
const isLoadingResetData = ref(false)
const setupWizard = ref(false)
const errors = ref({})
const dataIntegration = ref({
  provider: null,
  token: null,
  username: '',
  last_execution: null,
  last_queued: null,
  next_execution: null,
  trigger_url: null,
  cron: null,
  exists: false
})
watch(() => dataIntegration.value.provider, (newVal, oldVal) => {
  if (isLoading.value) {
    return
  }
  if (newVal && !oldVal) {
    return
  } else {
    dataIntegration.value.token = null
    dataIntegration.value.username = null
    dataIntegration.value.exists = false
  }
})

const sync = ref({
  status: null,
  message: null
})

const hasNoData = ref(true)
const showResetModal = ref(false)
const showExportModal = ref(false)
const timer = ref(null)

watch(() => showResetModal.value, (newVal) => {
  if (newVal) {
    timer.value = useTimer(Date.now() + 9_000)
  }
  else {
    timer.value = null
  }
})


async function fetch() {
  isLoading.value = true
  try {
    let response = await apiService.fetch(
      import.meta.env.VITE_API_URL + '/api/v1/dataIntegration'
    )
    dataIntegration.value = { ...response.data }
    setupWizard.value = !dataIntegration.value.exists
    if (setupWizard.value) {
      let [clientResponse, projectResponse, serviceResponse] = await Promise.all([
        apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/clients', { limit: 1 }),
        apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/projects', { limit: 1 }),
        apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/services', { limit: 1 }),
      ])
      if (clientResponse.meta.total != 0 || projectResponse.meta.total != 0 || serviceResponse.meta.total != 0) {
        hasNoData.value = false
      }

    }
  } catch (error) {
    errorLoading.value = true
    // useAlertsStore.error($t('errors.ups'))
    captureException(error)
  }
  isLoading.value = false
}
async function save() {
  isLoading.value = true
  try {
    let response = await apiService.update(
      import.meta.env.VITE_API_URL + '/api/v1/dataIntegration',
      null,
      dataIntegration.value
    )
    if (response.success) {
      let existsBefore = toRaw(dataIntegration.value.exists)
      dataIntegration.value = { ...response.data }
      if (dataIntegration.value.exists != existsBefore && !existsBefore) {
        // alertsStore.info($t('data_integrations.successfully_created_notice'), 60)
        // notificationsStore.send({
        //   type: 'dataIntegration.created.notice',
        //   options: {
        //     store: true,
        //     browser: {
        //       requireInteraction: true
        //     },
        //   },
        //   actions: {
        //     i18n: 'goto',
        //     route: { name: 'Integrations' }
        //   }
        // })
      }
      setupWizard.value = false
      alertsStore.successfullySaved()
      startSync()
    }
    errors.value = {}
  } catch (error) {
    if (error?.response?.status == 422) {
      errors.value = apiService.convertValidationErrors(error)
    } else if (apiService.checkIfServerError(error)) {
      console.error(error)
    } else {
      alertsStore.error($t('errors.ups_save'))
      captureException(error)
    }
  }
  isLoading.value = false
}
async function setProvider(provider) {
  dataIntegration.value.provider = provider
}
async function startSync() {
  sync.value.message = null
  isLoading.value = true
  try {
    let response = await axios.get(dataIntegration.value.trigger_url)
    sync.value.status = response.data.success
    if (!sync.value.status) {
      sync.value.message = response.data.message
    } else {
      sync.value.message = null
    }
  } catch (error) {
    if (apiService.checkErrorAndNotify(error)) {
      //
    }
    if (error?.response?.status == 429) {
      alertsStore.error($t('rate_limit.reached.quarter_hourly'))
    } else if (apiService.checkIfServerError(error, 30)) {
      //
    } else {
      alertsStore.error($t('errors.general'))
      captureException(error)
    }
  }
  isLoading.value = false
}
function backToInit() {
  dataIntegration.value.provider = null
  setupWizard.value = true
}

async function resetAllData() {
  isLoadingResetData.value = true
  try {
    await deleteClients()
    await setTimeout(async () => {
      try {
        await deleteServices()
        isLoadingResetData.value = false
        showResetModal.value = false
        setupWizard.value = false
        dataIntegration.value = {
          provider: null,
          token: null,
          username: '',
          last_execution: null,
          last_queued: null,
          next_execution: null,
          trigger_url: null,
          cron: null,
          exists: false
        }
        hasNoData.value = true
        fetch()
      }
      catch (error) {
        console.error(error)
      }
    }, 2_000)
  }
  catch (error) {
    console.error(error)
  }
  isLoadingResetData.value = false
}
async function deleteObjects(url, storeCallback, callableError = null) {
  let response = await apiService.fetchAll(url, {}, (response) => {
    response?.data?.forEach(async (item) => {
      try {
        await apiService.delete(url, item.id)
        storeCallback(item)
      }
      catch (error) {
        if (typeof callableError == 'function') {
          callableError(error, item)
        }
      }
    })
  })
}

async function deleteClients() {
  await deleteObjects(import.meta.env.VITE_API_URL + '/api/v1/clients', (item) => {
    clientsStore.removeId(item.id)
  })
}

async function deleteServices() {
  await deleteObjects(
    import.meta.env.VITE_API_URL + '/api/v1/services', (item) => {
      servicesStore.removeId(item.id)
    },
    (error, item) => {
      if (error?.response?.status == 409) {
        //
      }
    })
}

onMounted(() => {
  fetch()
  window.echo
    .private('dataIntegration.' + companyStore.company.id)
    .listen('DataIntegrationUpdated', (payload) => {
      dataIntegration.value = { ...payload.data }
      if (!dataIntegration.value.exists) {
        setupWizard.value = true
      }
    })
    .listen('.dataIntegration.updated', (payload) => {
      dataIntegration.value = { ...payload.data }
      if (!dataIntegration.value.exists) {
        setupWizard.value = true
      }
    })
})

onBeforeUnmount(() => {
  window.echo.leave('dataIntegration.' + companyStore.company.id)
})

</script>
