import apiService from '@/services/api.service'
import { defineStore, acceptHMRUpdate } from 'pinia'
import { useAuthUserStore } from './auth-user'
import axios from 'axios'
import { captureException, captureMessage } from '@sentry/vue'
import { useRoute } from 'vue-router'
import { useAlertsStore } from './alerts'
import datetime from '@/lib/datetime'
import { $t } from '@/config/i18n'
import { nextTick } from 'vue'

export const useCompanyStore = defineStore('company', {
  /**
   *
   * @returns {{id: ?string, company: ?{
   * id: string,
   * name: string,
   * address: ?string,
   * city: ?string,
   * zip: ?string,
   * country: ?string,
   * phone: ?string,
   * email: ?string,
   * invoice_email: ?string,
   * licenses: ?number,
   * oauth: Object,
   * force_oauth: boolean,
   * oauth_provider: ?string,
   * push_provider: ?string,
   * pull_provider: ?string,
   * autoupdate_quantity: ?boolean,
   * subscription: ?{
   *  status: ?('active'|'canceled'),
   * product: ?string,
   * quantity: ?number,
   * trial: boolean,
   * trial_ends_at: ?string,
   * ends_at: ?string
   *  },
   * stripe_exists: ?boolean,
   * active_clients_count: ?number,
   * active_projects_count: ?number,
   * active_services_count: ?number,
   * active_users_count: ?number,
   * disabled_clients_count: ?number,
   * disabled_projects_count: ?number,
   * disabled_services_count: ?number
   * },
   * shouldShowFirstDataSetup: boolean
   * }
   * }
   */
  state: () => ({
    id: null,
    company: null,
    shouldShowFirstDataSetup: false
  }),
  persist: true,
  getters: {
    subscriptionActive: (state) => {
      return state.company?.subscription?.status && state.company?.subscription?.status != 'canceled'
    }
  },
  actions: {
    async initFetch() {
      if (this.id == null) {
        this.id = useAuthUserStore().companyId
      }
      await this.fetch()

      try {
        this.shouldShowFirstDataSetup = await this.checkIfShouldShowFirstDataSetup()
      }
      catch (error) {
        console.error(error)
        captureException(error)
        this.shouldShowFirstDataSetup = false
      }

      if (typeof this.shouldShowFirstDataSetup != 'boolean') {
        this.shouldShowFirstDataSetup = false
      }
      this.checkAndShowCancellationAlert()
    },

    async fetch(callback) {
      try {
        let response = await apiService.fetch(
          import.meta.env.VITE_API_URL + '/api/v1/company',
        )
        if (response?.data) {
          this.internalUpdate(response.data)
          if (typeof callback == 'function') {
            callback()
          }
        }
        else {
          captureMessage('Company request comes back empty.', [response.data ?? []])
        }
      }
      catch (error) {
        captureException(error)
        console.error(error)
      }
    },

    internalUpdate(entry) {
      this.id = entry.id
      if (this.company) {
        if (this.company.tl == undefined || this.company.tl == null) {
          this.company.tl = {
            isDirty: false,
            origin: null
          }
        }
        this.company.tl.origin = { ...entry }

        if (!this.company.tl.isDirty) {
          Object.entries(entry).forEach((item) => {
            this.company[item[0]] = item[1]
          })
        }
      } else {
        this.company = {
          ...entry,
          tl: {
            origin: { ...entry },
            isDirty: false
          }
        }
      }
    },
    async updateData(data) {
      if (data) {
        Object.entries(data).forEach((item) => {
          this.company[item[0]] = item[1]
        })
      }
      return axios
        .patch(import.meta.env.VITE_API_URL + '/api/v1/companies/' + this.company.id, {
          name: this.company.name,
          address: this.company.address,
          email: this.company.email,
          invoice_email: this.company.invoice_email,
          city: this.company.city,
          zip: this.company.zip,
          phone: this.company.phone
        })
        .then((resp) => {
          if (resp.data.success) {
            this.company.tl.isDirty = false
            this.internalUpdate(resp.data.data)
          }
          return resp.data
        })
        .catch((error) => {
          if (
            Object.hasOwn(error, 'response') &&
            Object.hasOwn(error.response, 'status') &&
            (error.response.status == 401 || error.response.status == 419)
          ) {
            const authUserStore = useAuthUserStore()
            authUserStore.logout()
            window.location.reload()
          }
          throw error
        })
    },
    async checkIfShouldShowFirstDataSetup() {
      await nextTick()
      // if (this.router.currentRoute.value.)
      // To prevent loading of modal while on registration complete site
      if (this.router.currentRoute.value.name == 'CompleteRegistration') {
        return false
      }
      if (this.router.currentRoute.value.name == 'Integrations') {
        return false
      }
      if (this.router.currentRoute.value.name == 'ClientsList') {
        return false
      }
      if (this.router.currentRoute.value.name == 'ServicesList') {
        return false
      }
      if (this.router.currentRoute.value.name == 'UsersList') {
        return false
      }
      if (!this.company) {
        return false
      }
      if (this.company.active_clients_count ?? 1) {
        return false
      }
      if (this.company.active_projects_count ?? 1) {
        return false
      }
      if (this.company.active_services_count ?? 1) {
        return false
      }
      if (this.company.disabled_clients_count ?? 1) {
        return false
      }
      if (this.company.disabled_projects_count ?? 1) {
        return false
      }
      if (this.company.disabled_services_count ?? 1) {
        return false
      }
      if ((useAuthUserStore().user.admin ?? 0) < 9) {
        return false
      }
      return true
    },
    checkAndShowCancellationAlert() {
      let i18n_var = 'subscriptions.status.'
      let data = {}

      // START test

      // 2. end of trial without a subscription
      // i18n_var += "trial_ended_banner"

      // 3. subscription cancelled and active until 
      // i18n_var += "ends_at_banner"
      // data = {
      //   date: datetime.date("2024-12-30 12:00:15").toLocaleString(useAuthUserStore().user.language ?? 'de', {
      //     dateStyle: "short",
      //   })
      // }

      // 4. subscription cancelled and no time left

      // i18n_var += "canceled_banner"

      // useAlertsStore().warning($t(i18n_var, data), 1800)
      // return

      // END test

      // 1. trial phase or active subscription 
      if ((this.company?.subscription?.status ?? 'active') == 'active' && !(this.company?.subscription?.ends_at ?? null)) {
        return
      }

      // trial phase ended. Not set if company switched to an active subscription
      // 2. end of trial without a subscription
      if (this.company.subscription.trial_ends_at) {
        i18n_var += "trial_ended_banner"
      }
      // 3. subscription cancelled and active until 
      else if (this.company.subscription.ends_at && datetime.parse(this.company.subscription.ends_at) > Date.now()) {
        i18n_var += "ends_at_banner"
        data = {
          date: datetime.date(this.company.subscription.ends_at).toLocaleString(useAuthUserStore().user.language ?? 'de', {
            dateStyle: "short"
          })
        }
      }
      // 4. subscription cancelled and no time left
      else if (this.company.subscription.ends_at && datetime.parse(this.company.subscription.ends_at) <= Date.now()) {
        i18n_var += "canceled_banner"
      }
      if (i18n_var == 'subscriptions.status.') {
        i18n_var = 'subscriptions.status.trial_ended_banner'
      }

      // alerts
      useAlertsStore().warning($t(i18n_var, data), 1800)
    }
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCompanyStore, import.meta.hot))
}
