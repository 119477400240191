import { setSelectableClient } from "@/panel/helper/clients"
import Selection from "./Selection"
import ViewManager from "@/panel/ViewManager"
import { useClientsStore } from "@/stores/clients"
import { useCompanyStore } from "@/stores/company"
import { useTimeEntryStore } from "@/stores/timeEntry"
import { $t } from "@/config/i18n"

class Clients extends Selection {
    showButtonId = 1
    showButtonTextI18n = "panel.selection.active.client"
    /**
     * 
     * @param {ViewManager} manager 
     */
    header(manager) {
        this.setProject(manager)
        this.setService(manager)
    }

    getLastUsed(manager) {
        useClientsStore().fetchActiveCount()
        return useClientsStore().getLastUsed.filter((item) => item.active)
    }

    setSelectableItem(manager, id, item) {
        setSelectableClient(manager, id, item)
    }

    /**
         * 
         * @param {ViewManager} manager 
         * @returns 
         */
    getAlphabeticCount(manager) {
        useClientsStore().fetchActiveCount()
        return (useClientsStore()?.activeCount
            ? useClientsStore()?.activeCount
            : useCompanyStore()?.company?.active_clients_count) ?? 0
    }

    /**
    * @param  {ViewManager} manager
    * @returns {Array<any>}
    */
    getAlphabetic(manager) {
        return useClientsStore()
            .clients.filter((item) => item.active)
            .toSorted((a, b) => a.name.localeCompare(b.name))
    }

    /**
     * 
     * @param {ViewManager} manager 
     * @param {number} page 
     * @param {any} pagination 
     * @param {any} dims 
     * @param {any} needToLoadMore 
     */
    async fetchAlphabetic(manager, page, pagination, dims, needToLoadMore) {
        useClientsStore().fetch(
            {
                active: true,
                page: page + 2,
                limit: dims.columns * dims.rows * 2,
                orders: [
                    {
                        column: 'name',
                        direction: 'asc'
                    }
                ],
                withLimitedPartOfProjects: true
            },
            false,
            true,
            (data) => {
                useClientsStore().activeCount = data.meta.total
                if (manager.subView.value != 'client_id' || !pagination.next || !needToLoadMore) {
                    return
                }
                manager.setId(dims.rows * dims.columns - 1, manager.getPaginationNextButton(page))
            }
        )
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    async initFetch(manager) {
        const dims = manager.getDimensions()
        useClientsStore().fetch(
            {
                active: true,
                page: 1,
                limit: dims.columns * dims.rows * 2,
                orders: [
                    {
                        column: 'name',
                        direction: 'asc'
                    }
                ],
                withLimitedPartOfProjects: true
            },
            false,
            true
        )
    }

    canBeNull() {
        return !(useTimeEntryStore().requiredFields ?? []).includes('client_id')
    }

    /**
     * 
     * @param {ViewManager} manager 
     */
    setNullKey(manager) {
        const dims = manager.getDimensions()

        manager.setId(dims.columns, {
            type: 'text',
            text: $t('panel.select_no_client'),
            callback: () => {
                return () => {
                    const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
                    activeTimeEntry.client_id = null
                    activeTimeEntry.project_id = null
                    timelinkStoresService.updateTl(activeTimeEntry)
                    useTimeEntryStore().delayUpdateId(activeTimeEntry.id, 1000, true)
                    manager.switchToTracking()
                }
            }
        })
    }
}

export default new Clients()