import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from '@/services/timelink-stores.service'
import { nextTick, watch } from 'vue'
import { useClientsStore } from '@/stores/clients'

// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { useProjectsStore } from '@/stores/projects'
import { setCPS } from './cups'
import ViewManager from '../ViewManager'
import { $t } from '@/config/i18n'

/**
 *
 * @param {ViewManager} manager
 * @param {*} id
 * @param {*} item
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: import('../ViewManager').PanelGridItemCallback,
 *  interval: import('../ViewManager').PanelGridItemCallback,
 *  intervalTime: ?number,
 *  timeout: import('../ViewManager').PanelGridItemCallback,
 *  timeoutTime: ?number,
 *  init: import('../ViewManager').PanelGridItemCallback
 * }} _options
 */
function setClient(
  manager,
  id,
  item,
  // eslint-disable-next-line no-unused-vars
  _options = {
    color: null,
    bgColor: null,
    deckColor: null,
    deckBgColor: null,
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  setCPS(manager, id, item, _options)
}

/**
 *
 * @param {ViewManager} manager
 * @param {*} id
 * @param {*} item
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: import('../ViewManager').PanelGridItemCallback,
 *  interval: import('../ViewManager').PanelGridItemCallback,
 *  intervalTime: ?number,
 *  timeout: import('../ViewManager').PanelGridItemCallback,
 *  timeoutTime: ?number,
 *  init: import('../ViewManager').PanelGridItemCallback
 * }} _options
 */
function setSelectableClient(
  manager,
  id,
  item,
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  }
) {
  let dims = manager.getDimensions()
  manager.setIdForCPS(
    id,
    {
      color: _options.color ?? null,
      bgColor: _options.bgColor ?? null,
      init: (panelItem) => {
        //   manager.getImageFor(item, panelItem)
        let watcher = null
        watcher = (client) => {
          return watch(client, () => {
            timelinkStoresService.removeWatcher('panel', manager.getHandlerName(panelItem))
            manager.updateIdForCPS(
              id, {
              color: _options.color ?? null,
              bgColor: _options.bgColor ?? null,
            },
              item
            )
            timelinkStoresService.setOrRenewWatcher(
              'panel',
              manager.getHandlerName(panelItem),
              watcher(item)
            )
          })
        }
        timelinkStoresService.setOrRenewWatcher(
          'panel',
          manager.getHandlerName(panelItem),
          watcher(item)
        )
      },
      callback: () => {
        return () => {
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
          if (activeTimeEntry) {
            if (activeTimeEntry.client_id != item.id) {
              activeTimeEntry.project_id = null
            }
            activeTimeEntry.client_id = item.id
            timelinkStoresService.updateTl(activeTimeEntry)
            useTimeEntryStore().delayUpdateId(activeTimeEntry.id, 1000, true)
            manager.switchToTracking()
            useProjectsStore().fetchActiveCount(item, { client_id: item.id })
            useProjectsStore().fetch(
              {
                client_id: item.id,
                limit: dims.columns * dims.rows * 2,
                page: 1,
                orders: [
                  {
                    column: 'name',
                    direction: 'asc'
                  }
                ]
              },
              false,
              true
            )
          }
        }
      }
    },
    item
  )
}

/**
 *
 * @param {ViewManager} manager
 * @param {*} id
 * @param {boolean} showNoEntry
 * @param {?{
 *  color: ?string,
 *  bgColor: ?string,
 *  deckColor: ?string,
 *  deckBgColor: ?string,
 *  callback: import('../ViewManager').PanelGridItemCallback,
 *  interval: import('../ViewManager').PanelGridItemCallback,
 *  intervalTime: ?number,
 *  timeout: import('../ViewManager').PanelGridItemCallback,
 *  timeoutTime: ?number,
 *  init: import('../ViewManager').PanelGridItemCallback
 * }} _options
 */
function setActiveClient(
  manager,
  id,
  showNoEntry = false,
  // eslint-disable-next-line no-unused-vars
  _options = {
    callback: null,
    interval: null,
    intervalTime: null,
    timeout: null,
    timeoutTime: null,
    init: null
  },
) {
  const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
  if (!activeTimeEntry.client_id) {
    manager.setId(id, {
      type: 'text',
      text: $t(showNoEntry ? 'panel.no_client' : 'panel.select_client'),
      image: null,
      callback: () => {
        return () => {
          manager.switchToSelection('client_id')
        }
      },
      init: (item) => {
        console.log('init active client')
        timelinkStoresService.setOrRenewWatcher('panel', manager.getHandlerName(item), watch(() => activeTimeEntry.client_id, () => {
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
          console.log('watcher was here')
          if (activeTimeEntry && activeTimeEntry.client_id) {
            setActiveClient(manager, id, showNoEntry)
          }
        }))
        useTimeEntryStore().getActiveTimeEntry
      }
    })
  }
  else {
    manager.setIdForCPS(
      id,
      {
        color: _options.color ?? null,
        bgColor: _options.bgColor ?? null,
        init: (item) => {
          let watcher = null
          watcher = (timeEntry) => {
            let timeWatch = watch(timeEntry, async () => {
              timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
              const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

              if (!activeTimeEntry) {
                timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
                return
              }
              if (activeTimeEntry.client_id) {
                manager.updateIdForCPS(
                  id,
                  {
                    color: _options.color ?? null,
                    bgColor: _options.bgColor ?? null,
                  },
                  useClientsStore().getId(activeTimeEntry.client_id)
                )
              }
              else {
                setActiveClient(manager, id, showNoEntry)
                return
              }
              timelinkStoresService.setOrRenewWatcher(
                'panel',
                manager.getHandlerName(item),
                watcher(activeTimeEntry)
              )
            })
            let client = useClientsStore().getId(activeTimeEntry.client_id)
            let clientWatch = client
              ? watch(client, () => {
                timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
                manager.updateIdForCPS(
                  id,
                  {
                    color: _options.color ?? null,
                    bgColor: _options.bgColor ?? null,
                  },
                  useClientsStore().getId(activeTimeEntry.client_id)
                )
                timelinkStoresService.setOrRenewWatcher(
                  'panel',
                  manager.getHandlerName(item),
                  watcher(activeTimeEntry)
                )
              })
              : () => {
                //
              }
            return () => {
              timeWatch()
              clientWatch()
            }
          }
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            manager.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        },
        callback: () => {
          return () => {
            manager.switchToSelection('client_id')
          }
        }
      },
      useClientsStore().getId(activeTimeEntry.client_id)
    )
  }

}

export { setClient, setActiveClient, setSelectableClient }
