<template>
    <div class="">
        <Popover v-slot="{ open }">
            <PopoverButton class="button-white !px-2 aspect-square">
                <font-awesome-icon :icon="['fa-kit', 'tl-question']" size="lg" style="--fa-animation-duration: 1.5s;" />
            </PopoverButton>

            <PopoverPanel class="absolute z-10 -mt-2 w-96 right-5 top-20 bottom-20 drop-shadow">
                <div
                    class="grid items-center max-h-full grid-cols-5 gap-0 overflow-y-auto bg-white border divide-y rounded-xl border-mossgray-200 divide-mossgray-200">
                    <div class="col-span-3 px-4 py-2 font-bold">
                        {{ $t('help_center.header') }}
                    </div>

                    <LinkComponent href="https://docs.timelink.io">
                        {{ $t('help_center.documentation') }}
                    </LinkComponent>
                    <LinkComponent :href="mailto">
                        {{ $t('help_center.email') }}
                    </LinkComponent>
                    <LinkComponent href="https://timelink.io/kontakt">
                        {{ $t('help_center.contactform') }}
                    </LinkComponent>
                </div>
            </PopoverPanel>
        </Popover>
    </div>
</template>

<script setup>
import { $t } from '@/config/i18n'
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'
import { onMounted, ref } from 'vue'
import LinkComponent from './helpcenter/LinkComponent.vue';

const mailto = ref(null)
const browserType = window.navigator.userAgent

const version = APP_VERSION
const os = ref("")

onMounted(() => {
    if ('Navigator' in window) {
        if ('platform' in window.navigator) {
            os.value = window.navigator.platform
        }
    }
    const subject = $t('help_center.mailto.subject')
    const body = "\n\n\n" + $t('help_center.mailto.body') + "\nBrowser: " + browserType + "\nApp: " + version + "\n Betriebssystem: " + os.value
    const mail = atob("c3VwcG9ydEB0aW1lbGluay5pbw==");
    mailto.value = 'mailto:' + mail + '?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body)
})

</script>