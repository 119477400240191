import { createApp, markRaw } from 'vue'
import { createPinia } from 'pinia'
import { useAuthUserStore } from '@/stores/auth-user'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { all } from '@awesome.me/kit-0ef128f526/icons'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import axios from 'axios'
import Pusher from 'pusher-js'
import Echo from 'laravel-echo'
import './style.css'
import { useAppStore } from './stores/app'
import { buildWebStorage, setupCache } from 'axios-cache-interceptor'
import { Buffer } from 'buffer'
import logger from './services/logger.service'
import * as Sentry from '@sentry/vue'
import 'tippy.js/dist/tippy.css'
import VueTippy from 'vue-tippy'
import { i18n } from './config/i18n'
import deckService from './services/deck.service'
import { useDebuggingStore } from './stores/debugging'
import ViewManager from './panel/ViewManager'

window.Buffer = Buffer
const is_dev = import.meta.env.DEV

setupCache(axios, {
  // As localStorage is a public storage, you can add a prefix
  // to all keys to avoid collisions with other code.
  storage: buildWebStorage(localStorage, 'axios-cache:')
})

library.add(...all)
config.styleDefault = 'duotone'

window.Pusher = Pusher

const pinia = createPinia()
pinia.use(piniaPluginPersistedstate)
pinia.use(({ store }) => {
  store.router = markRaw(router)
})

// pinia.use(
//   PiniaSharedState({
//     enable: true,
//     initialize: false,
//     type: 'localstorage'
//   })
// )
const app = createApp(App)
app.use(i18n)
app.use(pinia)

const authUserStore = useAuthUserStore()
if (authUserStore.token !== null) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + authUserStore.token
}

window.echo = new Echo({
  authEndpoint: import.meta.env.VITE_API_URL + '/api/broadcasting/auth',
  auth: {
    headers: {
      Authorization: axios.defaults.headers.common['Authorization']
    }
  },
  broadcaster: 'pusher',
  key: import.meta.env.VITE_PUSHER_APP_KEY,
  wsHost: import.meta.env.VITE_PUSHER_HOST
    ? import.meta.env.VITE_PUSHER_HOST
    : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
  wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
  wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
  cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
  forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
  enabledTransports: ['ws', 'wss'],
  encrypted: true,
  disableStats: true
})

axios.interceptors.request.use((config) => {
  config.headers['X-Socket-ID'] = window.echo.socketId()
  // eslint-disable-next-line no-undef
  config.headers['X-App-Version'] = APP_VERSION
  return config
})
axios.interceptors.response.use((resp) => {
  try {
    if (resp.headers.has('X-Version-Incompatible')) {
      useAppStore().checkVersionDiff(resp.headers.get('X-Version-Incompatible'))
    }
  } catch (error) {
    try {
      if (resp.get('X-Version-Incompatible')) {
        useAppStore().checkVersionDiff(resp.headers.get('X-Version-Incompatible'))
      }
    } catch (error2) {
      if (resp.headers['X-Version-Incompatible'] ?? false) {
        useAppStore().checkVersionDiff(resp.headers.get('X-Version-Incompatible'))
      }
    }
  }
  return resp
})

const vueEcho = function (app) {
  app.config.globalProperties.$echo = window.echo
}

window.feedback = Sentry.feedbackIntegration({
  colorScheme: 'light',
  autoInject: false
})
// https://docs.sentry.io/platforms/javascript/guides/vue/#install
if (!is_dev) {
  Sentry.init({
    app,
    dsn: 'https://cc8fe55fde5c46ad3f9fd07563dd5192@o1324826.ingest.us.sentry.io/4507311866118144',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [500, 599]
      }),
      window.feedback
    ],
    ignoreErrors: [/CanceledError|Network Error/],
    // eslint-disable-next-line no-undef
    release: 'timelink-frontend@' + APP_VERSION,
    sampleRate: 1.0,
    tracesSampleRate: 0.1,
    trackComponents: true,
    tracePropagationTargets: [
      'localhost',
      /http:\/\/localhost\/api/,
      /https:\/\/([a-zA-Z]*\.)+timelink\.io\/api/
    ],
    maxBreadcrumbs: 100,
    // eslint-disable-next-line no-undef
    environment: process.env.NODE_ENV,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 1.0
  })
}

app.use(vueEcho)
app.use(router)
app.use(logger)
app.use(VueTippy, {
  defaultProps: {
    delay: [200, null]
  }
})

app.component('font-awesome-icon', FontAwesomeIcon)

app.mount('#app')

window.deck = deckService
window.viewManager = ViewManager
window.debuggingStore = useDebuggingStore()