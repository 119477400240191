<template>
  <div class="flex justify-end py-3 mx-6 mb-4 space-x-2 border-b border-mossgray-200">
    <ActiveTrackingComponent />
    <BaseButton class="button-apricot" @click="createTimeEntryModal = true" :only-active-sub="true">
      <font-awesome-icon :icon="['fa-kit', 'tl-plus']" class="mr-2 text-lg" />
      {{ $t('calendar.new_entry') }}
    </BaseButton>
    <HelpCenter />
    <NotificationCenter />
    <slot></slot>
  </div>
  <TimeEntryModalComponent v-model="createTimeEntryModal" v-model:item="timeEntryModalModel" />
</template>

<script setup>
import { ref, watch } from 'vue'
import ActiveTrackingComponent from './ActiveTrackingComponent.vue'
import TimeEntryModalComponent from '@/components/modals/TimeEntryModalComponent.vue'
import BaseButton from '@/components/general/BaseButton.vue'
import NotificationCenter from '@/components/NotificationCenter.vue'
import { useTimeEntryStore } from '@/stores/timeEntry'
import HelpCenter from './HelpCenter.vue'

const timeEntryStore = useTimeEntryStore()

const createTimeEntryModal = ref(false)
const timeEntryModalModel = ref(timeEntryStore.newEntry())
watch(timeEntryModalModel, (newVal) => {
  if (newVal == null) {
    timeEntryModalModel.value = timeEntryStore.newEntry()
  }
})
</script>
