import { usePanelStore } from '@/stores/panel'
import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from './timelink-stores.service'
import { reactive, ref } from 'vue'
import { icon, findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import { $t } from '@/config/i18n'
import viewLock from '@/panel/views/lock'
import deckService from './deck.service'

/**
 * @deprecated
 */
class PanelService {
  static blocks = null
  static view = null
  static page = null
  static images = null
  static fetchImageSignal = null

  getIcon(iconLookup, options) {
    const iconClass = icon(iconLookup, options)
    if (iconClass == undefined || iconClass == null) {
      return null
    }
    const div = document.createElement('div')
    div.innerHTML = iconClass.html.toString()
    let svg = div.firstChild
    const removeAttrs = ['aria-hidden', 'focusable', 'data-prefix', 'data-icon', 'class', 'role']
    removeAttrs.forEach((item) => {
      svg.removeAttribute(item)
    })
    svg.firstChild.removeAttribute('fill', '')
    svg.setAttribute('class', 'object-fit h-full w-full p-3 lg:p-4 xl:p-6')
    return div.innerHTML
  }

  loadActualPanelPage(view = 'start', page = null) {
    if (PanelService.blocks == null) {
      PanelService.blocks = reactive([])
    }

    if (PanelService.view == view && PanelService.page == page) {
      return PanelService.blocks
    }
    PanelService.view = view
    PanelService.page = page
    // PanelService.id = (Math.random() * 1000) % 1000
    return this.loadPanelPage(PanelService.blocks, view, page)
  }

  loadPanelPage(arr = null, view = 'start', page = null) {
    if (arr == null) {
      arr = reactive([])
    }

    if (!arr || arr.length == 0) {
      for (let i = 0; i < 15; i++) {
        arr.push(
          reactive({
            id: i,
            view: view,
            page: page,
            type: null,
            text: null,
            image: null,
            color: null,
            bgColor: null,
            deckColor: null,
            deckBgColor: null,
            callback: () => {
              return null
            },
            interval: () => {
              return null
            },
            intervalTime: null,
            timeout: () => {
              return null
            },
            timeoutTime: null,
            init: () => {
              return null
            }
          })
        )
      }
    } else {
      for (let i = 0; i < 15; i++) {
        this.resetId(arr, i, view, page)
      }
    }

    if (view == 'start') {
      viewStart(this, arr)
    } else if (view == 'recording') {
      viewRecording(this, arr)
    } else if (view == 'selectClient') {
      viewClients(this, arr, page)
    } else if (view == 'selectProject') {
      viewProjects(this, arr, page)
    } else if (view == 'selectService') {
      viewServices(this, arr, page)
    } else if (view == 'lock') {
      viewLock(this, arr, page)
    } else {
      this.setId(arr, 0, this.getBackButton())
    }

    for (let i = 0; i < 15; i++) {
      if (arr[i].id == undefined || arr[i].id == null) {
        arr[i]['id'] = i
      }
      if (arr[i].image == undefined) {
        arr[i]['image'] = null
      }
      if (arr[i].callback == undefined || arr[i].callback == null) {
        arr[i]['callback'] = () => {
          return null
        }
      }
      if (arr[i].init == undefined || arr[i].callback == null) {
        arr[i]['init'] = () => {
          return null
        }
      }
    }
    arr.forEach((item) => {
      this.startHandler(item, arr)
    })
    return arr
  }

  /**
   * @callback panelCallback
   * @param {{
   * type: ?string
   * }} item
   * @param {any[]} panelArr
   * @param {?string} type
   * @returns {?panelCallbackInside}
   */

  /**
   * @callback panelCallbackInside
   * @returns {any}
   */

  /**
   *
   * @param {any[]} arr
   * @param {number} i
   * @param {{
   *  type: ?string,
   *  text: ?string,
   *  image: ?string,
   *  color: ?string,
   *  bgColor: ?string,
   *  deckColor: ?string,
   *  deckBgColor: ?string,
   *  callback: panelCallback,
   *  interval: panelCallback,
   *  intervalTime: ?number,
   *  timeout: panelCallback,
   *  timeoutTime: ?number,
   *  init: panelCallback
   * }} data
   * @returns
   */
  setId(arr, i, data = {}) {
    if (arr[i] == undefined || arr[i] == null) {
      return
    }

    this.resetId(arr, i)
    this.updateId(arr, i, data)
  }

  /**
   *
   * @param {any[]} arr
   * @param {number} i
   * @param {{
   *  type: ?string,
   *  text: ?string,
   *  image: ?string,
   *  color: ?string,
   *  bgColor: ?string,
   *  deckColor: ?string,
   *  deckBgColor: ?string,
   *  callback: panelCallback,
   *  interval: panelCallback,
   *  intervalTime: ?number,
   *  timeout: panelCallback,
   *  timeoutTime: ?number,
   *  init: panelCallback
   * }} data
   * @returns
   */
  updateId(arr, i, data = {}) {
    if (arr[i] == undefined || arr[i] == null) {
      return
    }
    // console.log('set data for ' + i)
    Object.entries(data).forEach((item) => {
      arr[i][item[0]] = item[1]
      if (item[0] == 'init') {
        arr[i][item[0]](arr[i], arr)
      }
    })
  }

  /**
   *
   * @param {any[]} arr
   * @param {number} i
   * @param {{
   *  type: ?string,
   *  text: ?string,
   *  image: ?string,
   *  color: ?string,
   *  bgColor: ?string,
   *  deckColor: ?string,
   *  deckBgColor: ?string,
   *  callback: panelCallback,
   *  interval: panelCallback,
   *  intervalTime: ?number,
   *  timeout: panelCallback,
   *  timeoutTime: ?number,
   *  init: panelCallback
   * }} data
   * @param {{
   *  acronym: ?boolean,
   *  color: ?string,
   *  id: ?string,
   *  name: ?string,
   *  image_id: ?string
   * }} item
   */
  setIdForObjectWithAcronym(
    arr,
    i,
    data = {},
    item = { acronym: null, color: null, id: null, name: null, image_id: null }
  ) {
    if (!item) {
      this.setId(arr, i, {
        ...data
      })
      return
    }
    this.setIdWithImage(
      arr,
      i,
      {
        ...data,
        color:
          !item.image_id && item.acronym
            ? timelinkStoresService.generateForegroundColor(
              item.color ? item.color : timelinkStoresService.generateBackgroundColor(item)
            )
            : (data.color ?? null),
        bgColor:
          !item.image_id && item.acronym
            ? item.color
              ? item.color
              : timelinkStoresService.generateBackgroundColor(item)
            : (data.bgColor ?? null)
      },
      item
    )
  }

  /**
   *
   * @param {any[]} arr
   * @param {number} i
   * @param {{
   *  type: ?string,
   *  text: ?string,
   *  image: ?string,
   *  color: ?string,
   *  bgColor: ?string,
   *  deckColor: ?string,
   *  deckBgColor: ?string,
   *  callback: panelCallback,
   *  interval: panelCallback,
   *  intervalTime: ?number,
   *  timeout: panelCallback,
   *  timeoutTime: ?number,
   *  init: panelCallback
   * }} data
   * @param {{
   *  acronym: ?boolean,
   *  color: ?string,
   *  id: ?string,
   *  name: ?string,
   *  image_id: ?string
   * }} item
   */
  updateIdForObjectWithAcronym(
    arr,
    i,
    data = {},
    item = { acronym: null, color: null, id: null, name: null, image_id: null }
  ) {
    if (!item) {
      this.updateId(arr, i, {
        ...data
      })
      return
    }
    // console.log(item.color, data.color)
    this.updateIdWithImage(
      arr,
      i,
      {
        ...data,
        color: !item.image_id ? data.color ? data.color : (item.color ? item.color : timelinkStoresService.generateForegroundColor(
          item.color ? item.color : timelinkStoresService.generateBackgroundColor(item)
        )) : (data.color ?? null),
        bgColor: !item.image_id ? data.bgColor ? data.bgColor : (item.color ? timelinkStoresService.generateBackgroundColor(item.color) : null) : (data.color ?? null),
        // color:
        //   !item.image_id && (item.color || data.color)
        //     ? timelinkStoresService.generateForegroundColor(
        //       item.color ? item.color : timelinkStoresService.generateBackgroundColor(item)
        //     )
        //     : (data.color ?? null),
        // bgColor:
        //   !item.image_id && (item.color || data.bgColor)
        //     ? item.color
        //       ? item.color
        //       : timelinkStoresService.generateBackgroundColor(item)
        //     : (data.bgColor ?? null)
      },
      item
    )
  }

  /**
   *
   * @param {any[]} arr
   * @param {number} i
   * @param {{
   *  type: ?string,
   *  text: ?string,
   *  image: ?string,
   *  color: ?string,
   *  bgColor: ?string,
   *  deckColor: ?string,
   *  deckBgColor: ?string,
   *  callback: panelCallback,
   *  interval: panelCallback,
   *  intervalTime: ?number,
   *  timeout: panelCallback,
   *  timeoutTime: ?number,
   *  init: panelCallback
   * }} data
   * @param {{
   *  acronym: ?boolean,
   *  color: ?string,
   *  id: ?string,
   *  name: ?string,
   *  image_id: ?string
   * }} item
   */
  setIdWithImage(
    arr,
    i,
    data = {},
    item = { acronym: null, color: null, id: null, name: null, image_id: null }
  ) {
    if (!item) {
      this.setId(arr, i, {
        ...data
      })
      return
    }
    let item_init = data.init
    this.setId(arr, i, {
      ...data,
      type: item.image_id ? 'image' : 'text',
      init: (panelItem, panelArr) => {
        if (item.image_id) {
          this.getImageFor(item, panelItem)
        }
        return item_init(panelItem, panelArr)
      }
    })
  }

  /**
   *
   * @param {any[]} arr
   * @param {number} i
   * @param {{
   *  type: ?string,
   *  text: ?string,
   *  image: ?string,
   *  color: ?string,
   *  bgColor: ?string,
   *  deckColor: ?string,
   *  deckBgColor: ?string,
   *  callback: panelCallback,
   *  interval: panelCallback,
   *  intervalTime: ?number,
   *  timeout: panelCallback,
   *  timeoutTime: ?number,
   *  init: panelCallback
   * }} data
   * @param {{
   *  acronym: ?boolean,
   *  color: ?string,
   *  id: ?string,
   *  name: ?string,
   *  image_id: ?string
   * }} item
   */
  updateIdWithImage(
    arr,
    i,
    data = {},
    item = { acronym: null, color: null, id: null, name: null, image_id: null }
  ) {
    if (!item) {
      this.updateId(arr, i, {
        ...data
      })
      return
    }
    if (item.image_id) {
      this.getImageFor(item, arr[i])
    }
    let item_init = arr[i].init
    this.updateId(arr, i, {
      ...data,
      type: item.image_id ? 'image' : 'text',
      image: item.image_id ? arr[i].image : null
    })
    if (item.image_id) {
      this.getImageFor(item, arr[i])
    }
  }

  /**
   *
   * @param {any[]} arr
   * @param {number} i
   * @param {?string} view
   * @param {?string} page
   */
  resetId(arr, i, view = null, page = null) {
    timelinkStoresService.removeTimeout('panel', this.getHandlerName(arr[i]))
    timelinkStoresService.removeInterval('panel', this.getHandlerName(arr[i]))
    timelinkStoresService.removeWatcher('panel', this.getHandlerName(arr[i]))
    arr[i].view = view
    arr[i].page = page
    arr[i].type = null
    arr[i].text = null
    arr[i].image = null
    arr[i].color = null
    arr[i].bgColor = null
    arr[i].deckColor = null
    arr[i].deckBgColor = null
      ; (arr[i].callback = () => {
        return null
      }),
        (arr[i].interval = () => {
          return null
        }),
        (arr[i].init = () => {
          return
        }),
        (arr[i].intervalTime = null)
    arr[i].timeout = () => {
      return null
    }
    arr[i].timeoutTime = null
  }

  startHandler(item, arr = null) {
    if (item?.interval ?? null) {
      const interval = item.interval(item, arr)
      const intervalTime = item.intervalTime ?? 1000
      if (interval) {
        timelinkStoresService.setOrRenewInterval(
          'panel',
          this.getHandlerName(item),
          interval,
          intervalTime
        ),
          item.id,
          item.view,
          item.page
      }
    }
    if (item?.timeout ?? null) {
      const timeout = item.timeout(item, arr)
      const timeoutTime = item.timeoutTime ?? 1000
      if (timeout) {
        timelinkStoresService.setOrRenewTimeout(
          'panel',
          this.getHandlerName(item),
          timeout,
          timeoutTime
        ),
          item.id,
          item.view,
          item.page
      }
    }
  }

  loadActualPanelView() {
    if (usePanelStore().view != PanelService.view || usePanelStore().page != PanelService.page) {
      timelinkStoresService.removeAllIntervalsFrom('panel')
      timelinkStoresService.removeAllTimeoutsFrom('panel')
      timelinkStoresService.removeAllWatcher('panel')
      if (PanelService.fetchImageSignal) {
        PanelService.fetchImageSignal.abort()
        PanelService.fetchImageSignal = null
      }
      if (PanelService.fetchImageSignal == null) {
        PanelService.fetchImageSignal = new AbortController()
      }
    }
    return this.loadActualPanelPage(usePanelStore().view, usePanelStore().page)
  }
  reloadActualPanelView() {
    timelinkStoresService.removeAllIntervalsFrom('panel')
    timelinkStoresService.removeAllTimeoutsFrom('panel')
    timelinkStoresService.removeAllWatcher('panel')
    if (PanelService.fetchImageSignal) {
      PanelService.fetchImageSignal.abort()
      PanelService.fetchImageSignal = null
    }
    if (PanelService.fetchImageSignal == null) {
      PanelService.fetchImageSignal = new AbortController()
    }
    return this.loadPanelPage(PanelService.blocks, usePanelStore().view, usePanelStore().page)
  }
  getHandlerName(item) {
    let handlerName = ''
    if (item.view) {
      handlerName += item.view.toString() + '_'
    }
    if (item.page) {
      handlerName += item.page.toString() + '_'
    }
    if (item.id) {
      handlerName += item.id.toString()
    }
    return handlerName
  }
  getBackButton() {
    return {
      type: 'image',
      text: $t('back'),
      image: this.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-up-left' })),
      callback: () => {
        return () => {
          if (!useTimeEntryStore().getActiveTimeEntry) {
            usePanelStore().updateView('start')
          } else {
            usePanelStore().updateView('recording')
          }
        }
      }
    }
  }
  getCancelButton() {
    return {
      type: 'image',
      text: $t('cancel'),
      image: this.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-cross' })),
      color: '#f00',
      callback: () => {
        return () => {
          if (useTimeEntryStore().getActiveTimeEntry) {
            useTimeEntryStore().deleteId(useTimeEntryStore().getActiveTimeEntry.id)
          }
        }
      }
    }
  }

  getPagination(startRow, startColumn, maxRows, maxColumns, counter, page = 0) {
    let result = {
      startId: startColumn + startRow * maxColumns,
      start: 0,
      end: counter,
      pages: 0,
      previous: false,
      next: false
    }
    let maxPerPage = maxRows * maxColumns - (startColumn + startRow * maxColumns + 2)
    // console.log('start', maxPerPage)
    let firstPage = maxPerPage + 1
    // console.log('firstPage', firstPage)
    if (counter <= firstPage) {
      firstPage = firstPage + 1
    }
    let leftCounter = counter - firstPage
    // console.log('leftCounter', leftCounter)
    let pagesFloat = leftCounter / maxPerPage

    // console.log('pageFloat', pagesFloat)
    let pages = Math.ceil(pagesFloat) + 1
    // console.log('pages', pages)

    let lastPageItemsLeft = leftCounter % maxPerPage
    // console.log('lastPageItemsLeft', lastPageItemsLeft)
    if (lastPageItemsLeft <= 1) {
      pages = pages - 1
    }
    // console.log('pages', pages)
    result.pages = pages

    if (page == 0) {
      result.end = firstPage
      result.next = firstPage < counter

      return result
    }
    result.previous = true
    result.next = true
    result.start = firstPage + (page - 1) * maxPerPage
    // console.log('start', result.start, firstPage, (page - 1) * maxPerPage)
    result.end = result.start + maxPerPage
    // console.log('end', result.end, pages - 1)
    if (page == pages - 1) {
      result.end = result.end + 1
    }
    result.next = result.end >= counter ? false : true
    return result
  }

  getPaginationNextButton(page) {
    return {
      type: 'image',
      text: $t('next_page'),
      color: null,
      deckColor: null,
      image: this.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-right' })),
      callback: () => {
        return () => {
          usePanelStore().updatePage(page + 1)
        }
      }
    }
  }
  getPaginationPreviousButton(page) {
    return {
      type: 'image',
      text: $t('previous_page'),
      image: this.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-arrow-left' })),
      color: '#fff',
      callback: () => {
        return () => {
          usePanelStore().updatePage(page - 1)
        }
      }
    }
  }

  getDimensions() {
    return deckService.getDimensions()
  }
}

export default new PanelService()
