import apiService from "@/services/api.service";
import axios from "axios";
import { acceptHMRUpdate, defineStore } from "pinia";
import { ref } from "vue";


export const useImagesStore = defineStore('images', () => {

    /**
     * @type {import("vue").Ref<Array<{[key: string]: string}>>}
     */
    const images = ref({})
    /**
     * @type {import('vue').Ref<Array<{[key: string]: Promise}>>}
     */
    const ongoingRequest = ref({})


    function $reset() {
        // TODO: Add clear of local axios cache
        // images.value.keys().forEach((item) => {
        // axios.storage.remove()
        // }) 
        axios.storage.clear()
        images.value = {}
    }

    function addToStore(id, data) {
        images.value[id] = data
    }

    function has(id) {
        if (!id) {
            return false
        }
        return Boolean(images.value[id] ?? null)
    }

    function hasOngoingRequest(id) {
        return Boolean(ongoingRequest.value[id] ?? undefined)
    }

    return {
        images, $reset, fetch, get, getOrFetch, has
    }

    /**
     * 
     * @param {any} id 
     * @param {(function(any): any|null)} callback 
     * @returns 
     */
    async function fetch(id, callback) {
        if (!id) {
            return null
        }
        if (has(id)) {
            if (typeof callback == 'function') {
                callback(internalGet(id))
            }
            return internalGet(id)
        }
        return await internalFetch(id, callback)
    }

    async function internalFetch(id, callback) {
        if (!id) {
            if (typeof callback == 'function') {
                callback(null)
            }
            return null
        }
        try {
            let promise = null
            if (ongoingRequest.value[id] === undefined) {
                promise = new Promise((resolve) => {
                    apiService.fetch(import.meta.env.VITE_API_URL + '/api/v1/img/' + id).then((response) => {
                        ongoingRequest.value[id] = undefined
                        resolve(response)
                    })
                })
                ongoingRequest.value[id] = promise
            }
            else {
                promise = ongoingRequest.value[id]
            }
            let response = await promise

            if (response) {
                addToStore(id, response)
            }

            if (typeof callback == 'function') {
                callback(internalGet(id))
            }
            return internalGet(id)
            // return response.data
        }
        catch (error) {
            if (error?.response?.status == 404) {
                return null
            }
            console.error(error)
        }
    }

    /**
     * 
     * @param {string} id 
     * @returns {?string}
     */
    function get(id = null) {
        if (!id) {
            return null
        }
        if (images.value[id] == null) {
            fetch(id)
        }
        return internalGet(id)
    }

    function internalGet(id) {
        if (!id) {
            return null
        }
        if (images.value[id] === undefined) {
            images.value[id] = null
        }
        return images.value[id] ?? null
    }

    async function getOrFetch(id, callback) {
        if (has(id)) {
            if (typeof callback == 'function') {
                callback(images.value[id])
            }
            return internalGet(id)
        } else {
            let data = await internalFetch(id, callback)
            return data
        }
    }


}, {
    persist: true,
    pick: ['images']
})


if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useImagesStore, import.meta.hot))
}