<template>
  <div class="flex flex-row items-center py-2 pl-2 pr-6 group">
    <div class="flex items-center justify-center w-12 h-12 mr-4">
      <div class="flex items-center justify-center w-10 h-10 aspect-square squircle-clip" :style="{
        color: !client.image_id
          ? timelinkService.generateForegroundColor(
            client.color ? client.color : timelinkService.generateBackgroundColor(client)
          )
          : null,
        backgroundColor: !client.image_id
          ? client.color
            ? client.color
            : timelinkService.generateBackgroundColor(client)
          : null
      }">
        <img v-if="client.image_id && imagesStore.has(client.image_id)" :src="imagesStore.get(client.image_id)"
          class="w-48 squircle-clip aspect-square roboto-regular" />
        <span class="text-lg font-bold" v-else v-text="timelinkService.acronymOrShortName(client)"></span>
      </div>
    </div>

    <div class="flex flex-col">
      <div>
        <div class="font-medium" :class="{
          'text-mossgray-500 line-through ': !item.active
        }">
          <router-link :to="{ name: 'ClientEdit', params: { client_id: item.id } }"
            class="flex items-center gap-2 leading-5">
            {{ item.name }}</router-link>
        </div>
        <div v-text="$t('projects', item.projects_count ?? 0)" class="text-sm text-mossgray-800"></div>
      </div>
    </div>
  </div>
</template>

<script>
import timelinkStoresService from '@/services/timelink-stores.service'
import { useImagesStore } from '@/stores/images';

// vue default export
export default {
  props: ['client'],
  components: {},
  setup() {
    const timelinkService = timelinkStoresService
    const imagesStore = useImagesStore()
    return { timelinkService, imagesStore }
  },
  created() {
    this.item = { ...this.client }
  },
  mounted() {
    this.$echo
      .private('client.' + this.item.id)
      .listen('ClientUpdated', (payload) => {
        this.item = { ...this.item, ...payload.client }
      })
      .listen('.client.updated', (payload) => {
        this.item = { ...this.item, ...payload.client }
      })
  },
  beforeUnmount() {
    this.$echo.leave('client.' + this.item.id)
  },
  data() {
    return {
      item: {
        name: null,
        projects_count: 0
      }
    }
  },
  computed: {},
  methods: {}
}
</script>
