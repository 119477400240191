import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
// eslint-disable-next-line no-unused-vars
import deckService from '@/services/deck.service'
import ViewManager from '../ViewManager'

/**
 *
 * @param {ViewManager} manager
 */
function viewLock(manager) {
  const dims = manager.getDimensions()

  let id = dims.columns + Math.floor(dims.columns / 2)
  for (let i = 0; i < dims.rows * dims.columns; i++) {
    if (id == i) {
      continue
    }
    manager.setId(i, {
      type: 'text',
      text: '',
      bgColor: '#333333',
      deckBgColor: deckService.isTimepanel() ? '#333333' : '#000000'
    })
  }
  if(manager.debug) {
    console.log('Lock: set lock key')
  }
  manager.setId(id, {
    type: 'image',
    text: 'Gesperrt',
    bgColor: '#333333',
    deckBgColor: deckService.isTimepanel() ? '#333333' : '#000000',
    color: '#fff',
    image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-lock' })),
    callback: () => {
      return () => {
        manager.unlockIfLocked()
      }
    }
  })
}

export default viewLock
