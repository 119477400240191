import { useTimeEntryStore } from '@/stores/timeEntry'
import timelinkStoresService from '@/services/timelink-stores.service'
import { nextTick, watch } from 'vue'
import { useStopwatch } from 'vue-timer-hook'

import { findIconDefinition } from '@fortawesome/fontawesome-svg-core'
import desktopService from '@/services/desktop.service'
import router from '@/router/index'
import { $t } from '@/config/i18n'
// eslint-disable-next-line no-unused-vars
import panelService from '@/services/panel.service'
import { setActiveClient } from '../helper/clients'
import { setActiveProject } from '../helper/projects'
import { setActiveService } from '../helper/services'
import ViewManager from '../ViewManager'
import { captureException } from '@sentry/vue'
import { useAuthUserStore } from '@/stores/auth-user'
/**
 *
 * @param {ViewManager} manager
 * @param {*} arr
 * @param {*} page
 */
function viewTracking(manager) {
  const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry
  const user = useAuthUserStore().user

  manager.resetIds([0, 4, 5, 10, 14])
  // stop recording
  manager.setId(11, {
    type: 'image',
    text: $t('panel.stop_recoding'),
    image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-stop' })),
    init: (id) => {
      timelinkStoresService.setOrRenewWatcher('panel', manager.getHandlerName(id), watch(() => user?.settings?.panel?.wizard, () => {
        if (manager.hasActiveWizard() && manager.hasRemainingRequiredFields()) {
          manager.switchToTracking()
        }
      }))
    },
    callback: (id) => {
      return () => {
        useTimeEntryStore().stopActiveEntry(null, false, true)
        manager.switchToDashboard()
      }
    }
  })
  const stopwatch = useStopwatch(
    (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
    true
  )
  stopwatch.start()
  let daysText = ''
  if (stopwatch.days.value != 0) {
    daysText = stopwatch.days.value.toString().padStart(2, '0').toString() + ' d'
  }

  // <--- Start Time --->
  // Display days
  manager.setId(5, {
    type: 'text',
    text: daysText,
    callback: () => {
      return null
    },
    interval: (item) => {
      let watcher = null
      let updateText = (stopwatch) => {
        let newText = ''
        if (stopwatch.days.value != 0) {
          newText = stopwatch.days.value.toString().padStart(2, '0').toString() + ' d'
        }

        const panelItem = manager.getPanel()[item]
        if (panelItem.text != newText) {
          manager.updateId(item, {
            text: newText
          })
        }
      }
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeInterval('panel', manager.getHandlerName(item))
            return
          }

          const stopwatch = useStopwatch(
            (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
            true
          )
          stopwatch.start()
          updateText(stopwatch)

          timelinkStoresService.removeInterval('panel', manager.getHandlerName(item))
          timelinkStoresService.setOrRenewInterval('panel', manager.getHandlerName(item), () => {
            updateText(stopwatch)
          }, 10_000)
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            manager.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        manager.getHandlerName(item),
        watcher(activeTimeEntry)
      )
      return () => {
        updateText(stopwatch)
      }
    },
    intervalTime: 10_000
  })
  // hours
  manager.setId(6, {
    type: 'text',
    text: stopwatch.hours.value.toString().padStart(2, '0') + ' h',
    callback: () => {
      return null
    },
    interval: (item) => {
      let updateText = (stopwatch) => {
        const newText = stopwatch.hours.value.toString().padStart(2, '0').toString() + ' h'
        const panelItem = manager.getPanel()[item]
        if (panelItem.text != newText) {
          manager.updateId(item, {
            text: newText
          })
        }
      }
      let watcher = null
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeInterval('panel', manager.getHandlerName(item))
            return
          }

          const stopwatch = useStopwatch(
            (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
            true
          )
          stopwatch.start()
          updateText(stopwatch)
          timelinkStoresService.removeInterval('panel', manager.getHandlerName(item))
          timelinkStoresService.setOrRenewInterval('panel', manager.getHandlerName(item), () => {
            updateText(stopwatch)

          })
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            manager.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        manager.getHandlerName(item),
        watcher(activeTimeEntry)
      )
      return () => {
        updateText(stopwatch)
      }
    }
  })
  // minutes
  manager.setId(7, {
    type: 'text',
    text: stopwatch.minutes.value.toString().padStart(2, '0') + ' m',
    callback: () => {
      return null
    },
    interval: (item) => {
      let updateText = (stopwatch) => {
        const newText = stopwatch.minutes.value.toString().padStart(2, '0').toString() + ' m'
        const panelItem = manager.getPanel()[item]
        if (panelItem.text != newText) {
          manager.updateId(item, {
            text: newText
          })
        }
      }
      let watcher = null
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeInterval('panel', manager.getHandlerName(item))
            return
          }

          const stopwatch = useStopwatch(
            (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
            true
          )
          stopwatch.start()
          updateText(stopwatch)
          timelinkStoresService.removeInterval('panel', manager.getHandlerName(item))
          timelinkStoresService.setOrRenewInterval('panel', manager.getHandlerName(item), () => {
            updateText(stopwatch)
          })
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            manager.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        manager.getHandlerName(item),
        watcher(activeTimeEntry)
      )
      return () => {
        updateText(stopwatch)
      }
    }
  })
  // seconds
  manager.setId(8, {
    type: 'text',
    text: stopwatch.seconds.value.toString().padStart(2, '0') + ' s',
    callback: () => {
      return null
    },
    interval: (item) => {
      let updateText = (stopwatch) => {
        const newText = stopwatch.seconds.value.toString().padStart(2, '0').toString() + ' s'
        const panelItem = manager.getPanel()[item]
        if (panelItem.text != newText) {
          manager.updateId(item, {
            text: newText
          })
        }
      }
      let watcher = null
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeInterval('panel', manager.getHandlerName(item))
            return
          }

          const stopwatch = useStopwatch(
            (Date.now() - Date.parse(activeTimeEntry?.started_at)) / 1000,
            true
          )
          stopwatch.start()
          updateText(stopwatch)
          timelinkStoresService.removeInterval('panel', manager.getHandlerName(item))
          timelinkStoresService.setOrRenewInterval('panel', manager.getHandlerName(item), () => {
            updateText(stopwatch)

          }, 1000)
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            manager.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        manager.getHandlerName(item),
        watcher(activeTimeEntry)
      )
      return () => {
        updateText(stopwatch)

      }
    },
    intervalTime: 1000
  })

  manager.setId(9, {
    type: 'image',
    text: $t('duration'),
    image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-stopwatch' }))
  })

  manager.setId(12, {
    type: 'image',
    text: $t('panel.edit_description'),
    image:
      activeTimeEntry?.description == null ||
        activeTimeEntry?.description.trim() == '' ||
        activeTimeEntry?.description == ''
        ? manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-message-plus' }))
        : manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-message-pen' })),
    color:
      (activeTimeEntry?.description == null || activeTimeEntry?.description.trim() == '') &&
        useTimeEntryStore().requiredFields.includes('description')
        ? '#f00'
        : null,
    helper:
      activeTimeEntry?.description == null ||
        activeTimeEntry?.description.trim() == '' ||
        activeTimeEntry?.description == ''
        ? 'plus'
        : 'edit',
    init: (item) => {
      let watcher = null
      watcher = (timeEntry) => {
        return watch(timeEntry, () => {
          timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
          const activeTimeEntry = useTimeEntryStore().getActiveTimeEntry

          if (!activeTimeEntry) {
            timelinkStoresService.removeWatcher('panel', manager.getHandlerName(item))
            return
          }

          const panelItem = manager.getPanel()[item]
          if (
            activeTimeEntry?.description == null ||
            activeTimeEntry?.description.trim() == '' ||
            activeTimeEntry?.description == ''
          ) {
            if (panelItem.helper == 'edit') {
              manager.updateId(item, {
                image: manager.getIcon(
                  findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-message-plus' })
                ),
                helper: 'plus',
                color:
                  (activeTimeEntry?.description == null ||
                    activeTimeEntry?.description.trim() == '') &&
                    useTimeEntryStore().requiredFields.includes('description')
                    ? '#f00'
                    : null
              })
            }
          } else {
            if (panelItem.helper == 'plus') {
              manager.updateId(item, {
                image: manager.getIcon(
                  findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-message-pen' })
                ),
                helper: 'edit',
                color:
                  (activeTimeEntry?.description == null ||
                    activeTimeEntry?.description.trim() == '') &&
                    useTimeEntryStore().requiredFields.includes('description')
                    ? '#f00'
                    : null

              })
            }
          }
          timelinkStoresService.setOrRenewWatcher(
            'panel',
            manager.getHandlerName(item),
            watcher(activeTimeEntry)
          )
        })
      }
      timelinkStoresService.setOrRenewWatcher(
        'panel',
        manager.getHandlerName(item),
        watcher(activeTimeEntry)
      )
    },
    callback: () => {
      return () => {
        if (desktopService.isDesktop()) {
          desktopService.setStatus('overlayWindowActive', true)
        } else {
          let event = new Event('open-active-time-entry-modal-panel')
          window.dispatchEvent(event)
        }
      }
    }
  })
  manager.setId(13, {
    type: 'image',
    text: useTimeEntryStore().interruptedId
      ? $t('panel.end_interruption')
      : $t('panel.interrupt_for_new_recording'),
    image: useTimeEntryStore().interruptedId
      ? manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-bolt-slash' }))
      : manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-bolt' })),
    helper: useTimeEntryStore().interruptedId != null,
    init: (item) => {
      let sub = useTimeEntryStore().$subscribe((mutation, state) => {
        const panelItem = manager.getPanel()[item]
        if (panelItem.helper && !state.interruptedId) {
          manager.updateId(item, {
            image: manager.getIcon(findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-bolt' })),
            helper: false
          })
        } else if (!panelItem.helper && state.interruptedId) {
          manager.updateId(item, {
            image: manager.getIcon(
              findIconDefinition({ prefix: 'fa-kit', iconName: 'tl-bolt-slash' })
            ),
            helper: false
          })
        }
      })
      timelinkStoresService.setOrRenewWatcher('panel', manager.getHandlerName(item), sub)
    },
    callback: (item, panelArr, type) => {
      return async () => {
        manager.acquireLock()
        try {
          if (useTimeEntryStore().interruptedId) {
            await useTimeEntryStore().stopAndGoBackToInterruptedEntry(true)
          } else {
            await useTimeEntryStore().stopAndSaveAsInterruptedWithNewEntry(true)
          }

          manager.switchToTracking()
          manager.handlePanel()
          manager.releaseLock(10)
        }
        catch (error) {
          console.error(error)
          captureException(error)
          manager.releaseLock(10)
        }


      }
    }
  })
  // <--- End last row --->
  // <--- First row --->

  setActiveClient(manager, 1, true)
  setActiveProject(manager, 2, true)
  setActiveService(manager, 3, true)
}

export default viewTracking
