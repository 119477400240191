<template>
  <TemplateComponent>
    <loading-spinner v-model="isLoading" :fullPage="true"></loading-spinner>

    <div class="flex items-center w-full py-3 mb-3">
      <h1 class="mr-auto text-2xl font-bold">
        {{ $t('menu.settings_.general') }}
      </h1>

      <BaseButton class="button-mossgray" @click="downloadExport">
        <font-awesome-icon :icon="['fa-kit', 'tl-file-export']" class="mr-2 text-lg" />
        {{ $t('settings.general.export_time_entries') }}
      </BaseButton>
    </div>

    <div class="divide-y divide-mossgray-200">
      <div class="grid grid-cols-1 pt-3 pb-6 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7">
            {{ $t('calendar.settings.header') }}
          </h2>
          <p class="mt-1 text-sm leading-5">
            {{ $t('calendar.settings.description') }}
          </p>
        </div>

        <form class="bg-white ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="pb-8">
              <fieldset class="w-full space-y-4">
                <legend class="text-sm font-semibold">
                  {{ $t('calendar.settings.hidden_weekdays') }}
                </legend>
                <div class="grid w-full md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-7">
                  <template v-for="(hideWeekDay, n) in hideWeekDays" :key="n">
                    <div class="relative flex gap-x-3">
                      <div class="flex items-center h-6">
                        <input :name="'hideWeekdays[' + n + ']'" :id="'hideWeekdays[' + n + ']'" :value="n"
                          :checked="hideWeekDay" @change="updateHideWeekDays(n + 1)" type="checkbox" class="checkbox" />
                      </div>
                      <div class="text-sm">
                        <label :for="'hideWeekdays[' + n + ']'" class="font-medium">{{
                          weekDays[n]
                        }}</label>
                      </div>
                    </div>
                  </template>
                </div>
              </fieldset>
            </div>
            <div class="grid w-full grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="col-span-3">
                <span class="block text-sm font-medium">{{
                  $t('calendar.settings.visible_timezone')
                }}</span>
                <div class="flex mt-2 space-x-4">
                  <div>
                    <label for="showTimeIntervalStart" class="block text-sm font-medium leading-6">{{
                      $t('calendar.settings.start')
                    }}</label>
                    <input type="number" step="1" min="0" :max="showTimeIntervalEnd - 1" class="input"
                      v-model="showTimeIntervalStart" />
                    <div class="text-xs">{{ $t('default') }}: 6</div>
                  </div>
                  <div>
                    <label for="showTimeIntervalEnd" class="block text-sm font-medium leading-6">{{
                      $t('calendar.settings.end')
                    }}</label>
                    <input type="number" step="1" :min="showTimeIntervalStart + 1" max="24" class="input"
                      v-model="showTimeIntervalEnd" />
                    <div class="text-xs">{{ $t('default') }}: 20</div>
                  </div>
                </div>
              </div>
              <div class="col-span-3 col-start-1">
                <span class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('calendar.settings.automatic_stop_of_recording')
                }}</span>
                <div class="flex mt-2 space-x-4">
                  <div>
                    <label for="showTimeIntervalStart"
                      class="block pl-2 text-sm font-medium leading-6 text-gray-900"></label>
                    <input type="time" step="60" class="input" name="automatic_stop_of_recording"
                      v-model="automatic_stop_of_recording" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-mossgray-200 sm:px-8">
            <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
            <button type="button" @click="save" class="button-mossgray">{{ $t('save') }}</button>
          </div>
        </form>
      </div>
      <div class="grid grid-cols-1 py-6 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7">
            {{ $t('panel.settings.header') }}
          </h2>
          <p class="mt-1 text-sm leading-5 text-gray-600">
            {{ $t('panel.settings.description') }}
          </p>
        </div>

        <form class="bg-white ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-2xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <!-- TODO: Add tooltip for other browsers than electron -->
              <div class="sm:col-span-3">
                <label for="autoLockPanel" class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('panel.settings.autoLockPanel.header') }}</label>
                <div class="mt-2">
                  <input type="checkbox" name="autoLockPanel" id="autoLockPanel" autocomplete="Off"
                    v-model="autoLockPanel" class="checkbox" />
                </div>
              </div>
              <div class="sm:col-span-3" v-if="autoLockPanel && lockedStatePermission == true">
                <span class="block text-sm font-medium leading-6 text-gray-900">{{
                  $t('panel.settings.autoLockPanel.permissions')
                }}</span>
                <div class="mt-2">
                  <BaseButton @click="requestIdlePermission" class="button-mossgray" type="button">
                    {{ $t('panel.settings.autoLockPanel.permissions_request') }}
                  </BaseButton>
                </div>
              </div>
              <div class="sm:col-span-3" v-if="!hasWebhid">
                <i18n-t keypath="panel.settings.autoLockPanel.noWebhid" tag="span" for="link">
                  <template v-slot:link>
                    <!-- <a href="#">https://docs.timelink.io/connect_panel.html</a> -->
                    <a href="#">(Link folgt bald)</a>
                  </template>
                </i18n-t>
              </div>
              <div class="sm:col-span-3">
                <label for="panelWizard" class="block text-sm font-medium leading-6 text-gray-900"
                  v-tippy="{ content: $t('panel.settings.wizard.tooltip'), placement: 'top-start', arrow: false }">{{
                    $t('panel.settings.wizard.header') }}</label>
                <div class="mt-2">
                  <input type="checkbox" name="panelWizard" id="panelWizard" autocomplete="Off" v-model="panelWizard"
                    class="checkbox" />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label for="panelDefaultInnerView" class="block text-sm font-medium leading-6 text-gray-900"
                  v-tippy="{ content: $t('panel.settings.selection.innerView.tooltip'), placement: 'top-start', arrow: false }">{{
                    $t('panel.settings.selection.innerView.header') }}</label>
                <div class="pr-8 mt-2">
                  <select v-model="panelDefaultInnerView" class="w-full input" name="panelDefaultInnerView"
                    id="panelDefaultInnerView">
                    <template v-for="item in possibleInnerViews">
                      <option :value="item">{{
                        $t('panel.settings.selection.innerView.views.' + item) }}</option>
                    </template>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="flex items-center justify-end px-4 py-4 border-t gap-x-6 border-mossgray-200 sm:px-8">
            <!-- <button type="button" class="button-gray">Zurücksetzen</button> -->
            <button type="button" @click="save" class="button-mossgray">{{ $t('save') }}</button>
          </div>
        </form>
      </div>

      <div class="grid grid-cols-1 py-6 gap-x-8 gap-y-8 md:grid-cols-3">
        <div class="px-4 sm:px-0">
          <h2 class="text-lg font-bold leading-7">
            {{ $t('settings.general.about_timelink') }}
          </h2>
          <p class="mt-1 text-sm leading-5">
            {{ $t('settings.general.about_timelink_description') }}
          </p>
        </div>

        <div class="mb-12 space-y-2 bg-white divide-y ring-1 ring-mossgray-200 sm:rounded-3xl md:col-span-2">
          <div class="px-4 py-6 sm:p-8">
            <div class="grid max-w-4xl grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <span class="block text-sm font-medium leading-6 text-gray-900">
                  {{ $t('version') }}
                </span>
                <div class="mt-2">
                  <span class="font-bold">
                    {{ version ?? 'Development' }}
                  </span>
                </div>
              </div>

              <div class="sm:col-span-3" v-if="desktopService.isDesktop()">
                <span class="block text-sm font-medium leading-6 text-gray-900">
                  {{ $t('app_version') }}
                </span>
                <div class="mt-2">
                  <span class="font-bold">
                    {{ appVersion }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ExportModal v-model="showExportModal"></ExportModal>
  </TemplateComponent>
</template>

<script setup>
import { useAuthUserStore } from '@/stores/auth-user'
import DesktopService from '@/services/desktop.service'
import timelinkStoresService from '@/services/timelink-stores.service'
import featureFlagsService from '@/services/feature-flags.service'
import idleService from '@/services/idle.service'
import BaseButton from '@/components/general/BaseButton.vue'
import LoadingSpinner from '@/components/LoadingSpinner.vue'
import ExportModal from '@/components/modals/ExportModal.vue'
import { useTimeEntryStore } from '@/stores/timeEntry'
import TemplateComponent from './TemplateComponent.vue'
import datetime from '@/lib/datetime'
import { useAlertsStore } from '@/stores/alerts'
import apiService from '@/services/api.service'
import { captureException } from '@sentry/vue'
import { computed, onMounted, ref } from 'vue'
import { $t } from '@/config/i18n'
import ViewManager from '@/panel/ViewManager'

const authUserStore = useAuthUserStore()
const weekDays = datetime.getWeekDays()
const userAgent = window.navigator.userAgent
// eslint-disable-next-line no-undef
const version = APP_VERSION
let appVersion = null
let matches = userAgent.match(/([Tt]imelink\/[0-9]+\.[0-9]+\.[0-9]+)/)
if (matches && matches.length != 0) {
  appVersion = (matches[0] ?? '').substring('timelink/'.length)
}
const desktopService = DesktopService
const featureFlag = featureFlagsService
const idle = idleService
const hasWebhid = ref(false)
if (
  navigator != undefined &&
  navigator != null &&
  navigator.hid != undefined &&
  navigator.hid != null
) {
  hasWebhid.value = true
}

const hideWeekDaysArray = ref([])
const showTimeInterval = ref([6, 20])
const isDesktop = ref(false)
const autoLockPanel = ref(true)
const isLoading = ref(false)
const showExportModal = ref(false)
const automatic_stop_of_recording = ref(null)
const panelWizard = ref(true)
const panelDefaultInnerView = ref('last_used')
const possibleInnerViews = ref([])

const hideWeekDays = computed(() => {
  let arr = []
  for (let i = 1; i < 8; i++) {
    let j = hideWeekDaysArray.value.includes(i)
    arr.push(j)
  }
  return arr
})
const showTimeIntervalStart = computed({
  get() {
    return (showTimeInterval.value ?? [6, 20])[0]
  },
  set(value) {
    showTimeInterval.value[0] = value
  }
})
const showTimeIntervalEnd = computed({
  get() {
    return (showTimeInterval.value ?? [6, 20])[1]
  },
  set(value) {
    showTimeInterval.value[1] = value
  }
})

const lockedStatePermission = computed(() => {
  if (window.IdleDetector == undefined) {
    return null
  }
  return idle.needPermission.value
})

onMounted(() => {
  isDesktop.value = desktopService.isDesktop()
  hideWeekDaysArray.value = authUserStore.user?.settings?.hideWeekDays
  if (hideWeekDaysArray.value == null || hideWeekDaysArray.value == undefined) {
    hideWeekDaysArray.value = []
  }
  showTimeInterval.value = authUserStore.user?.settings?.showTimeInterval
  if (showTimeInterval.value == null || showTimeInterval.value == undefined) {
    showTimeInterval.value = [6, 20]
  }
  automatic_stop_of_recording.value = authUserStore.user?.settings?.automaticStopOfRecording ?? null
  autoLockPanel.value = authUserStore.user?.settings?.autoLockPanel ?? true

  panelWizard.value = (authUserStore.user?.settings?.panel?.wizard ?? true)
  panelDefaultInnerView.value = (authUserStore.user?.settings?.panel?.selection?.innerView ?? 'last_used')
  possibleInnerViews.value = ViewManager.fixedRotation
})

function updateHideWeekDays(n) {
  if (hideWeekDaysArray.value.includes(n)) {
    hideWeekDaysArray.value = hideWeekDaysArray.value.filter((i) => i != n)
  } else {
    hideWeekDaysArray.value.push(n)
  }
}
// TODO: Rework this function
async function save() {
  isLoading.value = true
  if (
    authUserStore.user.settings == null ||
    authUserStore.user.settings == undefined ||
    (Array.isArray(authUserStore.user.settings) && authUserStore.user.settings.length == 0) ||
    Object.entries(authUserStore.user.settings).length == 0
  ) {
    authUserStore.user.settings = {
      hideWeekDays: [],
      showTimeInterval: [6, 20],
      autoLockPanel: true,
      automaticStopOfRecording: null
    }
  }
  authUserStore.user.settings = {
    ...authUserStore.user.settings,
    hideWeekDays: hideWeekDaysArray.value,
    showTimeInterval: showTimeInterval.value,
    autoLockPanel: autoLockPanel.value,
    automaticStopOfRecording: automatic_stop_of_recording.value,
    panel: {
      wizard: panelWizard.value,
      selection: {
        innerView: panelDefaultInnerView.value
      }
    }
  }
  try {
    await authUserStore.updateUserData()
    useAlertsStore().successfullySaved()
  } catch (error) {
    if (apiService.checkIfServerError()) {
      //
    } else if (apiService.checkIfNotAuthenticated()) {
      return
    } else {
      useAlertsStore().error($t('errors.ups'))
      captureException(error)
    }
  }
  useTimeEntryStore().automaticStop()
  isLoading.value = false
}
function requestIdlePermission() {
  idle.requestPermission()
}
function downloadExport() {
  showExportModal.value = true
}
</script>
